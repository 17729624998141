import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import CartShippingAddress from "views/Pages/CartShippingAddress.js";
import CartView from "views/Pages/CartView.js";
import OrderView from "views/Pages/OrderView.js";
import OrderViewQuotation from "views/Pages/OrderViewQuotation";
import PaymentRecieved from "views/Pages/PaymentRecieved.js";
import LabourBillPrint from "views/Pages/LabourBillPrint.js";
import LabourAllBillViewPrint from "views/Pages/LabourAllBillViewPrint";
import LabourClientBillViewPrint from "views/Pages/LabourClientBillViewPrint";
import LabourAllBillViewPrintWithoutSize from "views/Pages/LabourAllBillViewPrintWithoutSize";
import LabourClientBillViewPrintWithoutSize from "views/Pages/LabourClientBillViewPrintWithoutSize";
import ViewCartPrint from "views/Pages/ViewCartPrint";
import ViewCancelCart from "views/Pages/ViewCancelCart.js"
// import ErrorPage from "views/Pages/ErrorPage.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

// import 'font-awesome/css/font-awesome.min.css';
// import { FontAwesomeIcon } from "./views/Fontawesomeicons/index.js";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/rtl" component={RtlLayout} />
      <Route path="/auth" component={AuthLayout} />
      <Route path="/admin" component={AdminLayout} />
      <Route path="/cart-shipping-address" component={CartShippingAddress} />
      <Route path="/cart-view" component={CartView} />
      <Route path="/order-view" component={OrderView} />
      <Route path="/order-view-quotation" component={OrderViewQuotation} />
      <Route path="/payment-recieved" component={PaymentRecieved} />
      <Route path="/labour-print" component={LabourBillPrint} />
      <Route path="/labour-all-print" component={LabourAllBillViewPrint} />
      <Route path="/labour-client-print" component={LabourClientBillViewPrint} />
      <Route path="/labours-all-print" component={LabourAllBillViewPrintWithoutSize} />
      <Route path="/labours-client-print" component={LabourClientBillViewPrintWithoutSize} />
      <Route path="/view-cart-print" component={ViewCartPrint} />
      <Route path="/view-cancel-cart" component={ViewCancelCart} />
      {/* <Route path="/error-page" component={ErrorPage} /> */}
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
