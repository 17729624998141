import React, { useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Selects from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);

export default function UpdateCart(props) {
  const [itemClient, setItemClient] = React.useState([]);
  const [cartItem, setCartItem] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [items2, setItems2] = React.useState([]);
  const [itemSets, setItemSets] = React.useState([]);
  const [itemBox, setItemBox] = React.useState([]);
  const [metal, setMetal] = React.useState([]);
  const [plastic, setPlastic] = React.useState([]);
  const [crystal, setCrystal] = React.useState([]);
  const [ploythene, setPloythene] = React.useState([]);
  const [simpleProductSelect, setSimpleProductSelect] = React.useState("");
  const [simpleCartItem, setSimpleCartItem] = React.useState("");
  const [imgList, setImgList] = React.useState("");
  const [imgListMore, setImgListMore] = React.useState("");
  const [newImage, setNewImage] = React.useState(false);
  const [simpleSelectProd, setSimpleSelectProd] = React.useState("");
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [selectedGstCal, setSelectedGstCal] = React.useState("cash");
  const [selectedGstCalTax, setSelectedGstCalTax] = React.useState("local");
  const [checkedA, setCheckedA] = React.useState(false);
  const [checkedTax, setCheckedTax] = React.useState(false);
  const [selectedProfit, setSelectedProfit] = React.useState("profit");
  const [selectedPieceSets, setSelectedPieceSets] = React.useState(0);
  const [selectedBox, setSelectedBox] = React.useState(0);
  const [makingCheckboxMaking, setMakingCheckboxMaking] = React.useState("");
  const [simpleSelectMetal, setSimpleSelectMetal] = React.useState("");
  const [readyGram, setReadyGram] = React.useState("0.0075");
  const [metalGram, setMetalGram] = React.useState("0.0100");
  const [metalSGram, setMetalSGram] = React.useState("0.0005");
  const [golwireThaw, setGolwireThaw] = React.useState("100 Thaw Golwire");
  const [packingOption, setPackingOption] = React.useState("");
  const [simpleSelectPlastic, setSimpleSelectPlastic] = React.useState("");
  const [simpleSelectCrystal, setSimpleSelectCrystal] = React.useState("");
  const [simpleSelectPloythene, setSimpleSelectPloythene] = React.useState("");
  const [checkBoxMaking, setCheckBoxmaking] = React.useState([]);
  const [makingSingleId, setMakingSingleId] = React.useState("");
  const [includeGST, setIncludeGST] = React.useState(0);
  const [includeSets, setIncludeSets] = React.useState(0);
  const [totalCash, setTotalCash] = React.useState(0);
  const [sets, setSets] = React.useState(0);
  const [prodItemCode, setProdItemCode] = React.useState("");
  const [prodDescription, setProdDescription] = React.useState("");
  const [prodMfgDetails, setProdMfgDetails] = React.useState("");
  const [cartonDescription, setCartonDescription] = React.useState(
    "96 boxes of in 1 Carton"
  );
  const [cartonQuantity, setCartonQuantity] = React.useState("1");
  const [cartonPackingCharge, setCartonPackingCharge] = React.useState(
    "500.00"
  );
  const [makingPrice, setMakingPrice] = React.useState(0);
  const [makingGst, setMakingGst] = React.useState(0);
  const [makIncludeGst, setMakIncludeGst] = React.useState(0);
  const [metalPrice, setMetalPrice] = React.useState(0);
  const [metalDiff, setMetalDiff] = React.useState(0);
  const [metalGst, setMetalGst] = React.useState(0);
  const [plasticPrice, setPlasticPrice] = React.useState(0);
  const [plasticGst, setPlasticGst] = React.useState(0);
  const [plasticDesc, setPlasticDesc] = React.useState("");
  const [plasticHsncode, setPlasticHsncode] = React.useState("");
  const [plasticActive, setPlasticActive] = React.useState("");
  const [crystalPrice, setCrystalPrice] = React.useState(0);
  const [crystalGst, setCrystalGst] = React.useState(0);
  const [crystalDesc, setCrystalDesc] = React.useState("");
  const [crystalHsncode, setCrystalHsncode] = React.useState("");
  const [crystalActive, setCrystalActive] = React.useState("");
  const [ployPrice, setPloyPrice] = React.useState(0);
  const [ployGst, setPloyGst] = React.useState(0);
  const [ployDesc, setPloyDesc] = React.useState("");
  const [ployHsncode, setPloyHsncode] = React.useState("");
  const [ployActive, setPloyActive] = React.useState("");
  const [makChecked, setMakChecked] = React.useState("");
  const [boxVal, setBoxVal] = React.useState(0);
  const [platVal, setPlatVal] = React.useState(0);
  const [makVal, setMakVal] = React.useState(0);
  const [metGramVal, setMetGramVal] = React.useState(0);
  const [packVal, setPackVal] = React.useState(0);

  const [totalProfit, setTotalProfit] = React.useState(0);
  const [setProfit, setSetProfit] = React.useState(0);

  const [comment, setComment] = React.useState(
    "Mix different four sizes in one design, or mix different six designs in one size, or one design in one size."
  );

  const [includeProfit, setIncludeProfit] = React.useState(0);
  const [includeProfitSet, setIncludeProfitSet] = React.useState(0);

  const [tax, setTax] = React.useState("0");

  const [catDes, setCatDes] = React.useState("");
  const [parentDes, setParentDes] = React.useState("");
  const [sizeDes, setSizeDes] = React.useState("");
  const [crtId, setCrtId] = React.useState("");

  const [clientid, setClientid] = React.useState("");
  const [simpleSelectClient, setSimpleSelectClient] = React.useState("");

  const [cat, setCat] = React.useState("");
  const [catSize, setCatSize] = React.useState("");
  const [checkBoxImage, setCheckBoxImage] = React.useState([]);
  const [makingCheckboxImage, setMakingCheckboxImage] = React.useState("");
  const [imgChecked, setImgChecked] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [size1x8, setSize1x8] = React.useState(0);
  const [size1x9, setSize1x9] = React.useState(0);
  const [size1x10, setSize1x10] = React.useState(0);
  const [size1x11, setSize1x11] = React.useState(0);
  const [size1x12, setSize1x12] = React.useState(0);
  const [size1x13, setSize1x13] = React.useState(0);
  const [size1x14, setSize1x14] = React.useState(0);
  const [size1x15, setSize1x15] = React.useState(0);
  const [size2x0, setSize2x0] = React.useState(0);
  const [size2x1, setSize2x1] = React.useState(0);
  const [size2x2, setSize2x2] = React.useState(0);
  const [size2x3, setSize2x3] = React.useState(0);
  const [size2x4, setSize2x4] = React.useState(0);
  const [size2x5, setSize2x5] = React.useState(0);
  const [size2x6, setSize2x6] = React.useState(0);
  const [size2x7, setSize2x7] = React.useState(0);
  const [size2x8, setSize2x8] = React.useState(0);
  const [size2x9, setSize2x9] = React.useState(0);
  const [size2x10, setSize2x10] = React.useState(0);
  const [size2x11, setSize2x11] = React.useState(0);
  const [size2x12, setSize2x12] = React.useState(0);
  const [size2x13, setSize2x13] = React.useState(0);
  const [size2x14, setSize2x14] = React.useState(0);
  const [size2x15, setSize2x15] = React.useState(0);
  const [size3x0, setSize3x0] = React.useState(0);
  const [size3x1, setSize3x1] = React.useState(0);
  const [checkedCutSize, setCheckedCutSize] = React.useState(false);
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);

  const [igst, setIgst] = React.useState("");
  const [cgst, setCgst] = React.useState("");
  const [sgst, setSgst] = React.useState("");

  const [metals, setMetals] = React.useState("");

  const [metalDetails, setMetalDetails] = React.useState("");
  const [boxDetails, setBoxDetails] = React.useState("");
  const [plasticDetails, setPlasticDetails] = React.useState("");
  const [crystalDetails, setCrytalDetail] = React.useState("");
  const [polytheneDetails, setPolytheneDetail] = React.useState("");

  const [clientDetails, setClientDetails] = React.useState("");

  const [simpleSelectClientErr, setSimpleSelectClientErr] = React.useState("");
  const [simpleCartItemErr, setSimpleCartItemErr] = React.useState("");
  const [newUpdateCart, setNewUpdateCart] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const [hsnCode, setHsnCode] = React.useState("");
  const [hsnCodeErr, setHsnCodeErr] = React.useState("");

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();

  let { avatar } = props;

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const totalPiece = "18";
  //   Math.ceil("864" / sets.pcs / box.box);

  const { state } = useLocation();

  async function getUserClient() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const results = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResults = await results.json();
    setItemClient(getResults && getResults.filter((f) => f.type != "Crm"));
    // console.log(getResults);
  }

  useEffect(() => {
    getUserClient();
  }, []);

  const handleClient = async (simpleSelectClient) => {
    if (simpleSelectClient.value) {
      let newSpli = simpleSelectClient.value.split("-");
      const ids = itemClient.find((f) => f.mobile === newSpli[1]);
      //   console.log(ids.id);
      setClientDetails(ids);
      setClientid(ids.id);
      axios
        .get(
          localApi +
            `/cart/expireCart/byAdminAndClientIdAndAcitve/${
              userObj && userObj.id
            }/${ids.id}?active=0`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          setCartItem(res && res.data);
          // console.log(res.data);
        });
    }
    setSimpleSelectClient(simpleSelectClient);
    setSimpleSelectClientErr("");
  };

  const handleCartItem = async (simpleCartItem) => {
    // console.log(simpleCartItem);
    setSimpleCartItem(simpleCartItem);
    setSimpleCartItemErr("");
    if (simpleCartItem.value) {
      let newCartItem = simpleCartItem.value.split("-");
      const itCart = cartItem.find((itemz) => itemz.id == newCartItem[0]);
      // console.log(itCart);
      setCrtId(itCart.id);
      setSimpleSelectProd(itCart.pId);
      setSimpleSelect(itCart.categoryId);
      setCatDes(itCart.categoryTitle);
      axios
        .get(localApi + `/parent/list/byCategoryId/${itCart.categoryId}`, {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        })
        .then((res) => setItems1(res && res.data));
      setSimpleSelect1(itCart.parentId);
      setParentDes(itCart.parentTitle);
      const setsObj = {
        id: itCart.setsId,
        setDesc: itCart.setsDesc,
        pcs: itCart.setsPcs,
      };
      setSelectedPieceSets(setsObj);
      const boxObj = {
        id: itCart.boxId,
        brandName: itCart.boxBrandName,
        boxDesc: itCart.boxDesc,
        pkgCharge: itCart.boxPkgCharge,
        box: itCart.box,
        hsnCode: itCart.boxHsnCode,
        price: itCart.boxPrice,
        tax_percentage: itCart.boxTaxPercentage,
      };
      setSelectedBox(boxObj);
      let boxVa = 0;
      let boxLoadGst = 0;
      if (itCart.boxId) {
        boxVa = itCart.boxPrice + itCart.boxPkgCharge;
        boxLoadGst = (itCart.boxPrice * itCart.boxTaxPercentage) / 100;
        setBoxVal(boxVa.toFixed(2));
      }
      const cApi = localApi + `/child/list/byParentId/${itCart.parentId}`;
      const cRresult = await fetch(cApi, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getC = await cRresult.json();
      setItems2(getC && getC);

      let tempPrice = 0;
      let tempMakVal = 0;
      let tempMakGstVal = 0;
      let tempMakGst = 0;
      setCheckBoxmaking(
        itCart.cartChild.map((ltist) => {
          tempPrice += ltist.price;
          tempMakVal += ltist?.price * itCart.setsPcs * itCart.box;
          tempMakGstVal += ltist.tax * itCart.setsPcs * itCart.box;
          tempMakGst += ltist.tax;
          return ltist.lbrId;
        })
      );
      // console.log(itCart.cartChild);
      setMakingPrice(tempPrice);
      setMakVal(tempMakVal);
      setMakIncludeGst(tempMakGst);
      setSimpleSelectMetal(itCart.rawId);
      const rawObj = {
        id: itCart.rawId,
        title: itCart.rawTitle,
        price: itCart.rawPrice,
        different: itCart.rawDifferent,
        tax: itCart.rawTax,
        taxPercentage: itCart.rawTaxPercentage,
      };
      setMetalDetails(rawObj);
      const metD = metal.find((metItem) => metItem.id == itCart.rawId);

      if (itCart.rawId) {
        setMetalPrice(itCart.rawPrice);
        setMetalDiff(itCart.rawDifferent);
        setMetalGst(itCart.rawTaxPercentage);
      }
      setMetalGram(itCart.metalGram);
      setReadyGram(itCart.readyGram);

      // console.log(metD);
      if (metD.id == itCart.rawId && metD.price === itCart.rawPrice) {
        setMetals("");
      } else {
        setMetals(itCart.rawPrice);
      }

      let newMet = 0;
      let metLoadGst = 0;
      if (itCart.metalGram) {
        newMet =
          itCart.metalGram *
          itCart.setsPcs *
          itCart.box *
          (itCart.rawPrice + itCart.rawDifferent);

        metLoadGst =
          (itCart.metalGram *
            itCart.setsPcs *
            itCart.box *
            (itCart.rawPrice + itCart.rawDifferent) *
            itCart.rawTaxPercentage) /
          100;
        setMetGramVal(newMet.toFixed(2));
      }
      // console.log(newMet);

      setMetalSGram(itCart.extraGram);
      setGolwireThaw(itCart.golwireThaw);

      setPackingOption(itCart.packingType);

      if (itCart.packingType === "Crystal Dibbi") {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${
                userObj && userObj.id
              }/Crystal Dibbi`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setCrystal(res && res.data));
        setSimpleSelectCrystal(itCart.packingId);
        setCrystalPrice(itCart.packingPrice);
        setCrystalGst(itCart.packingLigst);
        setCrystalDesc(itCart.packingDesc);
        setCrystalHsncode(itCart.packingHsncode);
      }

      if (itCart.packingType === "Polythene PP") {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${
                userObj && userObj.id
              }/Polythene PP`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setPloythene(res && res.data));
        setSimpleSelectPloythene(itCart.packingId);
        setPloyPrice(itCart.packingPrice);
        setPloyGst(itCart.packingLigst);
        setPloyDesc(itCart.packingDesc);
        setPloyHsncode(itCart.packingHsncode);
      }

      if (itCart.packingType === "Plastic Dibbi") {
        axios
          .get(
            localApi +
              `/packingmaterial/list/byAdminIdAndType/${
                userObj && userObj.id
              }/Plastic Dibbi`,
            {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            }
          )
          .then((res) => setPlastic(res && res.data));
        setSimpleSelectPlastic(itCart.packingId);
        setPlasticPrice(itCart.packingPrice);
        setPlasticGst(itCart.packingLigst);
        setPlasticDesc(itCart.packingDesc);
        setPlasticHsncode(itCart.packingHsncode);
      }

      let ployTotal = 0;
      let packLoadGst = 0;
      if (itCart.packingId) {
        ployTotal = itCart.packingPrice * itCart.box;
        packLoadGst =
          (itCart.packingPrice * itCart.box * itCart.packingLigst) / 100;
        setPackVal(ployTotal.toFixed(2));
      }

      const imId = itCart.cartImage.map((iList) => {
        return iList.imgId;
      });

      axios
        .get(
          localApi +
            `/image/list/byAdminAndCategoryAndParentId/${
              userObj && userObj.id
            }/${itCart.categoryId}/${itCart.parentId}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          // setImgList(res && res.data)
          const d = res.data.filter((f) => imId.includes(f.id));
          // console.log(d);

          const b = res.data.filter((f) => !imId.includes(f.id));
          // console.log(b);

          const arr = [...d, ...b];
          setImgList(d);
          setImgListMore(b);
        });

      setCheckBoxImage(
        itCart.cartImage.map((iList) => {
          return iList.imgId;
        })
      );

      setProdItemCode(itCart.itemCodeNumber);
      setProdMfgDetails(itCart.prodManfDetails);
      setProdDescription(itCart.prodDescription);
      setCartonDescription(itCart.cartDescription);
      setCartonQuantity(itCart.cartQuantity);
      setCartonPackingCharge(itCart.cartPackCharge);
      setCheckedA(itCart.active);
      setComment(itCart.comment);
      setHsnCode(itCart.hsnSacCode);
      // setSelectedGstCal(itCart.gstCash);

      if (itCart.gstCash === true) {
        setSelectedGstCal("gst");
      } else {
        setSelectedGstCal("cash");
      }

      //////////////////////////// Calculation Part Start ///////////////////////
      let boxPTotalCash = boxVa + tempMakVal + newMet + ployTotal;
      // console.log(boxPTotalCash);

      let boxPTotalGst = boxLoadGst + tempMakGstVal + metLoadGst + packLoadGst;
      setIncludeGST((boxPTotalCash + boxPTotalGst).toFixed(2));
      setTax(boxPTotalGst.toFixed(2));
      // console.log(boxPTotalGst);

      let setPGst = (boxPTotalCash + boxPTotalGst) / itCart.box;
      setIncludeSets(setPGst.toFixed(2));
      // console.log(setPGst);s

      let prodGst =
        (boxPTotalCash * userObj.profit) / 100 + boxPTotalGst + boxPTotalCash;
      setIncludeProfit(prodGst.toFixed(2));
      // console.log(prodGst);

      let prodSet = prodGst / itCart.box;
      setIncludeProfitSet(prodSet.toFixed(2));
      // console.log(prodSet);

      //// cash ////

      let boxPrices = boxVa + tempMakVal + newMet + ployTotal;
      setTotalCash(boxPrices.toFixed(2));

      let setPrice = boxPrices / itCart.box;
      setSets(setPrice.toFixed(2));
      // console.log(setPrice);

      let profit =
        (boxPrices * userObj.profit) / 100 + boxPrices + boxPTotalGst / 2;
      setTotalProfit(profit.toFixed(2));
      // console.log(profit);

      let setProfit = profit / itCart.box;
      setSetProfit(setProfit.toFixed(2));
      // console.log(setProfit);

      /////////////////////// Calculation Part End //////////////////////

      if (itCart.sizeCutOrFull == 3) {
        setCheckedFullSize(true);
        setCheckedCutSize(true);
      }
      if (itCart.sizeCutOrFull == 1) {
        setCheckedFullSize(true);
      }
      if (itCart.sizeCutOrFull == 2) {
        setCheckedCutSize(true);
      }

      // console.log(itCart);

      setCheckedTax(itCart.taxApply);
      setCgst(itCart.cgst);
      setSgst(itCart.sgst);
      setIgst(itCart.igst);

      if (itCart.saleType == 0) {
        setSelectedGstCalTax("local");
      } else {
        setSelectedGstCalTax("interested");
      }

      if (userObj.state === clientDetails.state) {
        setSelectedGstCalTax("local");
      } else {
        setSelectedGstCalTax("interested");
      }

      setSize1x8(itCart.size1x8);
      setSize1x9(itCart.size1x9);
      setSize1x10(itCart.size1x10);
      setSize1x11(itCart.size1x11);
      setSize1x12(itCart.size1x12);
      setSize1x13(itCart.size1x13);
      setSize1x14(itCart.size1x14);
      setSize1x15(itCart.size1x15);
      setSize2x0(itCart.size2x0);
      setSize2x1(itCart.size2x1);
      setSize2x2(itCart.size2x2);
      setSize2x3(itCart.size2x3);
      setSize2x4(itCart.size2x4);
      setSize2x5(itCart.size2x5);
      setSize2x6(itCart.size2x6);
      setSize2x7(itCart.size2x7);
      setSize2x8(itCart.size2x8);
      setSize2x9(itCart.size2x9);
      setSize2x10(itCart.size2x10);
      setSize2x11(itCart.size2x11);
      setSize2x12(itCart.size2x12);
      setSize2x13(itCart.size2x13);
      setSize2x14(itCart.size2x14);
      setSize2x15(itCart.size2x15);
      setSize3x0(itCart.size3x0);
      setSize3x1(itCart.size3x1);
    }
  };

  async function getUsers() {
    const api = localApi + `/product/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItemProd(getResult && getResult);
    // console.log(getResult);
  }

  useEffect(() => {
    getUsers();
  }, []);

  // useEffect(() => {
  //   setSimpleSelectProd(state && state ? state.id : "");
  //   setSimpleSelect(state && state ? state.cartCategory?.categoryId : "");
  //   axios
  //     .get(
  //       localApi +
  //         `/parent/list/byAdminIdAndCategoryId/${userObj.id}/${
  //           state && state ? state.cartCategory?.categoryId : ""
  //         }`
  //     )
  //     .then((res) => setItems1(res.data));
  //   setSimpleSelect1(state && state ? state.cartParent?.parentId : "");
  //   setSelectedPieceSets(state && state ? state.cartSets : "");
  //   setSelectedBox(state && state ? state.box : "");
  //   let boxVa = 0;
  //   let boxLoadGst = 0;
  //   if (state && state ? state.box?.id : "") {
  //     boxVa =
  //       (state && state ? state.box?.price : "") +
  //       (state && state ? state.box?.pkgCharge : "");
  //     boxLoadGst =
  //       ((state && state ? state.box?.price : "") *
  //         (state && state ? state.box?.tax_percentage : "")) /
  //       100;
  //     setBoxVal(boxVa.toFixed(2));
  //   }
  //   axios
  //     .get(
  //       localApi +
  //         `/child/list/byAdminIdAndParentId/${userObj.id}/${
  //           state && state ? state.parent.id : ""
  //         }`
  //     )
  //     .then((res) => setItems2(res.data));

  //   let tempPrice = 0;
  //   let tempMakVal = 0;
  //   let tempMakGstVal = 0;
  //   let tempMakGst = 0;
  //   setCheckBoxmaking(
  //     state && state
  //       ? state.productChild.map((ltist) => {
  //           tempPrice += ltist.price;
  //           tempMakVal +=
  //             ltist?.price *
  //             (state && state ? state.cartSets?.pcs : "") *
  //             (state && state ? state.box?.box : "");
  //           tempMakGstVal +=
  //             ltist.tax *
  //             (state && state ? state.cartSets?.pcs : "") *
  //             (state && state ? state.box?.box : "");
  //           tempMakGst += ltist.tax;
  //           return ltist.lbrId;
  //         })
  //       : ""
  //   );
  //   setMakingPrice(tempPrice);
  //   setMakVal(tempMakVal);
  //   setMakIncludeGst(tempMakGst);
  //   setSimpleSelectMetal(state && state ? state.rawPrice.id : "");
  //   if (state && state ? state.rawPrice?.id : "") {
  //     setMetalPrice(state && state ? state.rawPrice?.price : "");
  //     setMetalDiff(state && state ? state.rawPrice?.different : "");
  //     setMetalGst(state && state ? state.rawPrice?.taxPercentage : "");
  //   }
  //   setMetalGram(state && state ? state.metalGram : "");
  //   setReadyGram(state && state ? state.readyGram : "");
  //   let newMet = 0;
  //   let metLoadGst = 0;
  //   if (state && state ? state.metalGram : "") {
  //     newMet =
  //       (state && state ? state.metalGram : "") *
  //       (state && state ? state.cartSets?.pcs : "") *
  //       (state && state ? state.box?.box : "") *
  //       ((state && state ? state.rawPrice?.price : "") +
  //         (state && state ? state.rawPrice?.different : ""));

  //     metLoadGst =
  //       ((state && state ? state.metalGram : "") *
  //         (state && state ? state.cartSets?.pcs : "") *
  //         (state && state ? state.box?.box : "") *
  //         ((state && state ? state.rawPrice?.price : "") +
  //           (state && state ? state.rawPrice?.different : "")) *
  //         (state && state ? state.rawPrice?.taxPercentage : "")) /
  //       100;
  //     setMetGramVal(newMet.toFixed(2));
  //   }
  //   // console.log(newMet);

  //   setMetalSGram(state && state ? state.extraGram : "");
  //   setGolwireThaw(state && state ? state.golwireThaw : "");

  //   setPackingOption(state && state ? state.packing?.type : "");

  //   if (state && state ? state.packing?.type : "" === "Crystal Dibbi") {
  //     axios
  //       .get(
  //         localApi +
  //           `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Crystal Dibbi`
  //       )
  //       .then((res) => setCrystal(res.data));
  //     setSimpleSelectCrystal(state && state ? state.packing?.id : "");
  //   }

  //   if (state && state ? state.packing?.type : "" === "Polythene PP") {
  //     axios
  //       .get(
  //         localApi +
  //           `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Polythene PP`
  //       )
  //       .then((res) => setPloythene(res.data));
  //     setSimpleSelectPloythene(state && state ? state.packing?.id : "");
  //   }

  //   if (state && state ? state.packing?.type : "" === "Plastic Dibbi") {
  //     axios
  //       .get(
  //         localApi +
  //           `/packingmaterial/list/byAdminIdAndType/${userObj.id}/Plastic Dibbi`
  //       )
  //       .then((res) => setPlastic(res.data));
  //     setSimpleSelectPlastic(state && state ? state.packing?.id : "");
  //   }

  //   let ployTotal = 0;
  //   let packLoadGst = 0;
  //   if (state && state ? state.packing?.id : "") {
  //     ployTotal =
  //       (state && state ? state.packing?.price : "") *
  //       (state && state ? state.box?.box : "");
  //     packLoadGst =
  //       ((state && state ? state.packing?.price : "") *
  //         (state && state ? state.box?.box : "") *
  //         (state && state ? state.packing?.ligst : "")) /
  //       100;
  //     setPackVal(ployTotal.toFixed(2));
  //     // console.log(ployTotal);
  //     setPloyPrice(state && state ? state.packing?.price : "");
  //     setPloyGst(state && state ? state.packing?.ligst : "");
  //     setPlasticPrice(state && state ? state.packing?.price : "");
  //     setPlasticGst(state && state ? state.packing?.ligst : "");
  //     setCrystalPrice(state && state ? state.packing?.price : "");
  //     setCrystalGst(state && state ? state.packing?.ligst : "");
  //   }

  //   const imId =
  //     state && state
  //       ? state.cartImage.map((iList) => {
  //           return iList.imgId;
  //         })
  //       : "";

  //   axios
  //     .get(
  //       localApi +
  //         `/image/list/byAdminAndCategoryAndParentId/${userObj.id}/${
  //           state && state ? state.cartCategory.categoryId : ""
  //         }/${state && state ? state.cartParent.parentId : ""}`
  //     )
  //     .then((res) => {
  //       // setImgList(res.data)
  //       const d = res.data.filter((f) => imId.includes(f.id));
  //       // console.log(d);

  //       const b = res.data.filter((f) => !imId.includes(f.id));
  //       // console.log(b);

  //       const arr = [...d, ...b];
  //       setImgList(d);
  //       setImgListMore(b);
  //     });

  //   setCheckBoxImage(
  //     state && state
  //       ? state.cartImage.map((iList) => {
  //           return iList.imgId;
  //         })
  //       : ""
  //   );

  //   setProdItemCode(state && state ? state.itemCodeNumber : "");
  //   setProdMfgDetails(state && state ? state.prodManfDetails : "");
  //   setProdDescription(state && state ? state.prodDescription : "");
  //   setCartonDescription(state && state ? state.cartDescription : "");
  //   setCartonQuantity(state && state ? state.cartQuantity : "");
  //   setCartonPackingCharge(state && state ? state.cartPackCharge : "");
  //   setCheckedA(state && state ? state.active : "");
  //   setHsnCode(state && state ? state.hsnSacCode : "");
  //   // setSelectedGstCal(state.gstCash);

  //   if (state && state ? state.gstCash : "" === true) {
  //     setSelectedGstCal("gst");
  //   } else {
  //     setSelectedGstCal("cash");
  //   }

  //   //////////////////////////// Calculation Part Start ///////////////////////
  //   let boxPTotalCash = boxVa + tempMakVal + newMet + ployTotal;
  //   // console.log(boxPTotalCash);

  //   let boxPTotalGst = boxLoadGst + tempMakGstVal + metLoadGst + packLoadGst;
  //   setIncludeGST((boxPTotalCash + boxPTotalGst).toFixed(2));
  //   setTax(boxPTotalGst.toFixed(2));
  //   // console.log(boxPTotalGst);

  //   let setPGst =
  //     (boxPTotalCash + boxPTotalGst) / (state && state ? state.box.box : "");
  //   setIncludeSets(setPGst.toFixed(2));
  //   // console.log(setPGst);

  //   let prodGst =
  //     (boxPTotalCash * userObj.profit) / 100 + boxPTotalGst + boxPTotalCash;
  //   setIncludeProfit(prodGst.toFixed(2));
  //   // console.log(prodGst);

  //   let prodSet = prodGst / (state && state ? state.box.box : "");
  //   setIncludeProfitSet(prodSet.toFixed(2));
  //   // console.log(prodSet);

  //   //// cash ////

  //   let boxPrices = boxVa + tempMakVal + newMet + ployTotal;
  //   setTotalCash(boxPrices.toFixed(2));

  //   let setPrice = boxPrices / (state && state ? state.box.box : "");
  //   setSets(setPrice.toFixed(2));
  //   // console.log(setPrice);

  //   let profit =
  //     (boxPrices * userObj.profit) / 100 + boxPrices + boxPTotalGst / 2;
  //   setTotalProfit(profit.toFixed(2));
  //   // console.log(profit);

  //   let setProfit = profit / (state && state ? state.box.box : "");
  //   setSetProfit(setProfit.toFixed(2));
  //   // console.log(setProfit);

  //   /////////////////////// Calculation Part End //////////////////////
  // }, []);

  const handleSimpleProd = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    setSimpleSelectProd(value);
  };

  const fetchCat = async () => {
    const catApi =
      localApi + `/category/list/byAdminId/${userObj && userObj.id}`;
    const catRresult = await fetch(catApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getCat = await catRresult.json();
    // console.log(getSet);
    setItems(getCat && getCat);
  };

  const fetchSet = async () => {
    const setsApi = localApi + `/sets/list/byAdminId/${userObj && userObj.id}`;
    const setRresult = await fetch(setsApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getSet = await setRresult.json();
    // console.log(getSet);
    setItemSets(getSet && getSet);
  };

  const fetchBox = async () => {
    const boxApi = localApi + `/box/list/byAdminId/${userObj && userObj.id}`;
    const boxRresult = await fetch(boxApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getBox = await boxRresult.json();
    // console.log(getSet);
    setItemBox(getBox && getBox);
  };

  const fetchMetal = async () => {
    const metalApi =
      localApi + `/rawprice/list/byAdminId/${userObj && userObj.id}`;
    const metalRresult = await fetch(metalApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getMetal = await metalRresult.json();
    // console.log(getChild);
    setMetal(getMetal && getMetal);
  };

  useEffect(() => {
    // catApi().then((res) => setItems(res));
    fetchCat();
    fetchSet();
    fetchBox();
    fetchMetal();
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id == value);
    setSimpleSelect(value);
    if (value) {
      setCatDes(item.title);
    }
    // setGetId(item.id);
    // setItems1(item.parent);
    const response = await axios.get(
      `${localApi}/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
    setCat(item);
  };

  const handleSimple1 = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    setSimpleSelect1(value);
    const itemm = items1.find((itemm) => itemm.id == value);
    setCatSize(itemm);
    const childApi = localApi + `/child/list/byParentId/${itemm.id}`;
    const childRresult = await fetch(childApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getChild = await childRresult.json();
    // console.log(getChild);
    setItems2(getChild && getChild);
    const imageApi =
      localApi +
      `/image/list/byAdminAndCategoryAndParentId/${userObj && userObj.id}/${
        cat.id
      }/${itemm.id}`;
    const imageRresult = await fetch(imageApi, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getImage = await imageRresult.json();
    // console.log(getImage);
    setImgList(getImage && getImage);
  };

  const handleChangeGstCalTax = (event) => {
    // setSelectedGstCal(event.target.value);
    const { value } = event.target;
    setSelectedGstCalTax(value);
  };

  const handleChangeGstCal = (event, id) => {
    // setSelectedGstCal(event.target.value);
    const { value } = event.target;
    const metText = parseInt(metals) + metalDiff;
    setSelectedGstCal(value);
    if (value === "gst") {
      setSelectedGstCal(value);

      setCheckedTax(true);

      let boxPkgGst = (selectedBox?.price * selectedBox?.tax_percentage) / 100;
      let boxPkgCash = selectedBox?.price + selectedBox?.pkgCharge;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let makPriceCash =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metals ? metText : metalPrice + metalDiff) *
          metalGst) /
          100 || 0;
      let metPriceCash =
        metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metals ? metText : metalPrice + metalDiff) || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100
          : 0;
      let plasPriceCash =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100
          : 0;
      let crysPriceCash =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100
          : 0;
      let ployPriceCash =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      let boxPriceGst =
        boxPkgGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;

      let boxPriceCash =
        boxPkgCash +
        makPriceCash +
        metPriceCash +
        plasPriceCash +
        crysPriceCash +
        ployPriceCash +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;

      setTax(boxPriceGst.toFixed(2));
      setIncludeGST((boxPriceCash + boxPriceGst).toFixed(2));

      let setPriceGst = (boxPriceGst + boxPriceCash) / selectedBox?.box;
      setIncludeSets(setPriceGst.toFixed(2));

      let profGst =
        (boxPriceCash * userObj.profit) / 100 + boxPriceGst + boxPriceCash;
      setIncludeProfit(profGst.toFixed(2));

      let profSet = profGst / selectedBox?.box;
      setIncludeProfitSet(profSet.toFixed(2));
    } else {
      setCheckedTax(false);

      let boxPkg = selectedBox?.price + selectedBox?.pkgCharge;
      let makPrice =
        makingPrice * selectedPieceSets?.pcs * selectedBox?.box || 0;
      let metPrice =
        metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metals ? metText : metalPrice + metalDiff) || 0;
      let plasPrice =
        packingOption === "Plastic Dibbi" ? plasticPrice * selectedBox?.box : 0;
      let crysPrice =
        packingOption === "Crystal Dibbi" ? crystalPrice * selectedBox?.box : 0;
      let ployPrices =
        packingOption === "Polythene PP" ? ployPrice * selectedBox?.box : 0;

      /// Gst Part ////
      let boxPkgGst = (selectedBox?.price * selectedBox?.tax_percentage) / 100;

      let makPriceGst =
        makIncludeGst * selectedPieceSets?.pcs * selectedBox?.box || 0;

      let metPriceGst =
        (metalGram *
          selectedPieceSets?.pcs *
          selectedBox?.box *
          (metals ? metText : metalPrice + metalDiff) *
          metalGst) /
          100 || 0;

      let plasPriceGst =
        packingOption === "Plastic Dibbi"
          ? (plasticPrice * selectedBox?.box * plasticGst) / 100
          : 0;

      let crysPriceGst =
        packingOption === "Crystal Dibbi"
          ? (crystalPrice * selectedBox?.box * crystalGst) / 100
          : 0;

      let ployPriceGst =
        packingOption === "Polythene PP"
          ? (ployPrice * selectedBox?.box * ployGst) / 100
          : 0;

      let boxPriceGst =
        boxPkgGst +
        makPriceGst +
        metPriceGst +
        plasPriceGst +
        crysPriceGst +
        ployPriceGst +
        mixplatPriceGst +
        mixmakPriceGst +
        mixmetPriceGst;
      //// Gst part End ///////

      let boxPrices =
        boxPkg +
        makPrice +
        metPrice +
        plasPrice +
        crysPrice +
        ployPrices +
        mixplatingPrice +
        mixmakPrice +
        mixmetPrice;
      setTotalCash(boxPrices.toFixed(2));
      // console.log(boxPrices);

      let setPrice = boxPrices / selectedBox?.box;
      setSets(setPrice.toFixed(2));

      let profit =
        (boxPrices * userObj.profit) / 100 + boxPrices + boxPriceGst / 2;
      setTotalProfit(profit.toFixed(2));
      // console.log("Gst/2 = 33.255/- ", boxPriceGst / 2);

      let setProfit = profit / selectedBox?.box;
      setSetProfit(setProfit.toFixed(2));
    }
  };

  const handleProfit = (e) => {
    setSelectedProfit(e.target.value);
  };

  const handleCheckBoxMaking = (event, making) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = event.target;
    if (checked) {
      setMakingPrice(makingPrice + making?.price);
    } else {
      setMakingPrice(makingPrice - making?.price);
    }

    if (checked) {
      setMakVal(
        makVal + making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    } else {
      setMakVal(
        makVal - making?.price * selectedPieceSets?.pcs * selectedBox?.box
      );
    }

    if (checked) {
      setMakingGst(making?.taxPercentage);
      setMakIncludeGst(makIncludeGst + making?.tax);
    } else {
      setMakingGst("");
      setMakIncludeGst(makIncludeGst - making?.tax);
    }

    if (checked) {
      setMakingSingleId(making?.id);
    }

    setCheckBoxmaking((prev) => {
      if (prev.includes(making.id)) {
        return prev.filter((x) => x !== making.id);
      } else {
        return [...prev, making.id];
      }
    });

    // setMakChecked((prev) => {
    //   if (prev.includes(making?.id)) {
    //     return prev.filter((x) => x !== making?.id);
    //   } else {
    //     return [...prev, making?.id];
    //   }
    // });
  };

  const handleSimpleMetal = (event) => {
    // alert(event.target.value);
    const { value } = event.target;
    const metItem = metal.find((metItem) => metItem.id == value);
    setMetalDetails(metItem);
    setSimpleSelectMetal(value);
    setMetalPrice(metItem.price);
    setMetalDiff(metItem.different);
    setMetalGst(metItem.taxPercentage);
    if (value) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metItem.price + metItem.different);
      setMetGramVal(newMet.toFixed(2));
    }
  };

  const handlePackingOption = async (event) => {
    // setPackingOption(event.target.value);
    const { value } = event.target;
    setPackingOption(value);
    if (value === "Crystal Dibbi") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/Crystal Dibbi`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setCrystal(getResult && getResult);
    } else if (value === "Plastic Dibbi") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/Plastic Dibbi`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setPlastic(getResult && getResult);
    } else if (value === "Polythene PP") {
      const api =
        localApi +
        `/packingmaterial/list/byAdminIdAndType/${
          userObj && userObj.id
        }/Polythene PP`;
      const result = await fetch(api, {
        method: "GET",
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      });
      const getResult = await result.json();
      setPloythene(getResult && getResult);
    }
    if (value === "Plastic Dibbi") {
      let packTotal = plasticPrice * selectedBox?.box;
      // console.log("plas", packTotal);
      setPackVal(packTotal.toFixed(2));
    } else if (value === "Crystal Dibbi") {
      let crysTotal = crystalPrice * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    } else if (value === "Polythene PP") {
      let ployTotal = ployPrice * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    }
  };

  const handleSimplePlastic = (event) => {
    // setSimpleSelectPlastic(event.target.value);
    const { value } = event.target;
    setSimpleSelectPlastic(value);
    const plaItem = plastic.find((plaItem) => plaItem.id == value);
    if (value) {
      setPlasticDetails(plaItem);
    }
    setPlasticPrice(plaItem.price);
    setPlasticGst(plaItem.ligst);
    setPlasticDesc(plaItem.pkgdesc);
    setPlasticHsncode(plaItem.hsncode);
    setPlasticActive(plaItem.active);
    if (packingOption === "Plastic Dibbi" && value) {
      let packTotal = plaItem.price * selectedBox?.box;
      setPackVal(packTotal.toFixed(2));
    }
  };

  const handleSimpleCrystal = (event) => {
    // setSimpleSelectCrystal(event.target.value);
    const { value } = event.target;
    setSimpleSelectCrystal(value);
    const crysItem = crystal.find((crysItem) => crysItem.id == value);
    if (value) {
      setCrytalDetail(crysItem);
    }
    setCrystalPrice(crysItem.price);
    setCrystalGst(crysItem.ligst);
    setCrystalDesc(crysItem.pkgdesc);
    setCrystalHsncode(crysItem.hsncode);
    setCrystalActive(crysItem.active);
    if (packingOption === "Crystal Dibbi" && value) {
      let crysTotal = crysItem.price * selectedBox?.box;
      setPackVal(crysTotal.toFixed(2));
    }
  };

  const handleSimplePloythene = (event) => {
    // setSimpleSelectPloythene(event.target.value);
    const { value } = event.target;
    setSimpleSelectPloythene(value);
    const ployItem = ploythene.find((ployItem) => ployItem.id == value);
    if (value) {
      setPolytheneDetail(ployItem);
    }
    setPloyPrice(ployItem.price);
    setPloyGst(ployItem.ligst);
    setPloyDesc(ployItem.pkgdesc);
    setPloyHsncode(ployItem.hsncode);
    setPloyActive(ployItem.active);
    if (packingOption === "Polythene PP" && value) {
      let ployTotal = ployItem.price * selectedBox?.box;
      setPackVal(ployTotal.toFixed(2));
    }
  };

  const handleCheckBoxImage = (e, i) => {
    // setCheckBoxmaking(e.target.checked);
    const { checked } = e.target;

    setCheckBoxImage((prev) => {
      if (prev.includes(i.id)) {
        return prev.filter((x) => x !== i.id);
      } else {
        return [...prev, i.id];
      }
    });

    // setImgChecked((prev) => {
    //   if (prev.includes(i?.id)) {
    //     return prev.filter((x) => x !== i?.id);
    //   } else {
    //     return [...prev, i?.id];
    //   }
    // });
  };

  var fullSize =
    parseInt(size1x8) +
    parseInt(size1x10) +
    parseInt(size1x12) +
    parseInt(size1x14) +
    parseInt(size2x0) +
    parseInt(size2x2) +
    parseInt(size2x4) +
    parseInt(size2x6) +
    parseInt(size2x8) +
    parseInt(size2x10) +
    parseInt(size2x12) +
    parseInt(size2x14) +
    parseInt(size3x0);
  var cutSize =
    parseInt(size1x9) +
    parseInt(size1x11) +
    parseInt(size1x13) +
    parseInt(size1x15) +
    parseInt(size2x1) +
    parseInt(size2x3) +
    parseInt(size2x5) +
    parseInt(size2x7) +
    parseInt(size2x9) +
    parseInt(size2x11) +
    parseInt(size2x13) +
    parseInt(size2x15) +
    parseInt(size3x1);

  const SubmitUpdateProduct = () => {
    setNewUpdateCart(true);
    let errorCount = 0;
    if (simpleSelectClient === "" || !simpleSelectClient) {
      errorCount++;
      setSimpleSelectClientErr("Select Client Id");
    }
    if (simpleCartItem === "" || !simpleCartItem) {
      errorCount++;
      setSimpleCartItemErr("Select Cart Id");
    }
    if (errorCount === 0) {
      setBtn(true);
      const cgstTax =
        checkedTax === false
          ? checkedTax === false
            ? selectedGstCalTax === "local"
              ? cgst
                ? cgst
                : 0
              : 0
            : 0
          : checkedTax === true
          ? selectedGstCalTax === "local"
            ? cgst
              ? cgst
              : 0
            : 0
          : 0;

      const sgstTax =
        checkedTax === false
          ? checkedTax === false
            ? selectedGstCalTax === "local"
              ? sgst
                ? sgst
                : 0
              : 0
            : 0
          : checkedTax === true
          ? selectedGstCalTax === "local"
            ? sgst
              ? sgst
              : 0
            : 0
          : 0;

      const igstTax =
        checkedTax === false
          ? checkedTax === false
            ? selectedGstCalTax === "interested"
              ? igst
                ? igst
                : 0
              : 0
            : 0
          : checkedTax === true
          ? selectedGstCalTax === "interested"
            ? igst
              ? igst
              : 0
            : 0
          : 0;

      var per = 100.0;
      var cTax = cgstTax;
      var sTax = sgstTax;
      var iTax = igstTax;
      var localPer = parseFloat(per) + parseFloat(cTax) + parseFloat(sTax);
      var interestedPer = parseFloat(per) + parseFloat(iTax);
      var local = parseFloat(cTax) + parseFloat(sTax);
      var interested = parseFloat(iTax);

      const imgAll = [...imgList, ...imgListMore];

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Basic ${credentials}`,
        },
        body: JSON.stringify({
          boxId: selectedBox.id,
          boxBrandName: selectedBox.brandName,
          boxDesc: selectedBox.boxDesc,
          boxPkgCharge: selectedBox.pkgCharge,
          box: selectedBox.box,
          boxHsnCode: selectedBox.hsnCode,
          boxPrice: selectedBox.price,
          boxTaxPercentage: selectedBox.tax_percentage,
          categoryId: simpleSelect,
          categoryTitle: catDes,
          parentId: simpleSelect1,
          parentTitle: parentDes,
          setsId: selectedPieceSets.id,
          setsDesc: selectedPieceSets.setDesc,
          setsPcs: selectedPieceSets.pcs,
          rawId: metalDetails.id,
          rawTitle: metalDetails.title,
          rawPrice: metals ? metals : metalDetails.price,
          rawDifferent: metalDetails.different,
          rawTax: metalDetails.tax,
          rawTaxPercentage: metalDetails.taxPercentage,
          packingId:
            packingOption === "Plastic Dibbi"
              ? simpleSelectPlastic
              : packingOption === "Crystal Dibbi"
              ? simpleSelectCrystal
              : packingOption === "Polythene PP"
              ? simpleSelectPloythene
              : "",
          packingType:
            packingOption === "Plastic Dibbi"
              ? packingOption
              : packingOption === "Crystal Dibbi"
              ? packingOption
              : packingOption === "Polythene PP"
              ? packingOption
              : "",
          packingDesc:
            packingOption === "Plastic Dibbi"
              ? plasticDesc
              : packingOption === "Crystal Dibbi"
              ? crystalDesc
              : packingOption === "Polythene PP"
              ? ployDesc
              : "",
          packingHsncode:
            packingOption === "Plastic Dibbi"
              ? plasticHsncode
              : packingOption === "Crystal Dibbi"
              ? crystalHsncode
              : packingOption === "Polythene PP"
              ? ployHsncode
              : "",
          packingPrice:
            packingOption === "Plastic Dibbi"
              ? plasticPrice
              : packingOption === "Crystal Dibbi"
              ? crystalPrice
              : packingOption === "Polythene PP"
              ? ployPrice
              : "",
          packingLigst:
            packingOption === "Plastic Dibbi"
              ? plasticGst
              : packingOption === "Crystal Dibbi"
              ? crystalGst
              : packingOption === "Polythene PP"
              ? ployGst
              : "",
          firstName: clientid.firstname,
          lastName: clientid.lastname,
          company: clientid.company,
          metalGram: metalGram,
          extraGram: metalSGram,
          readyGram: readyGram,
          golwireThaw: golwireThaw,
          itemCodeNumber: prodItemCode,
          prodManfDetails: prodMfgDetails,
          prodDescription: prodDescription,
          cartDescription: cartonDescription,
          cartQuantity: cartonQuantity,
          cartPackCharge: cartonPackingCharge,
          active: false,
          gstCash: selectedGstCal === "gst" ? true : false,
          taxApply: checkedTax,
          saleType: selectedGstCalTax === "local" ? "0" : "1",
          cgst:
            checkedTax === false
              ? checkedTax === false
                ? selectedGstCalTax === "local"
                  ? cgst
                    ? cgst
                    : 0
                  : 0
                : 0
              : checkedTax === true
              ? selectedGstCalTax === "local"
                ? cgst
                  ? cgst
                  : 0
                : 0
              : 0,
          sgst:
            checkedTax === false
              ? checkedTax === false
                ? selectedGstCalTax === "local"
                  ? sgst
                    ? sgst
                    : 0
                  : 0
                : 0
              : checkedTax === true
              ? selectedGstCalTax === "local"
                ? sgst
                  ? sgst
                  : 0
                : 0
              : 0,
          igst:
            checkedTax === false
              ? checkedTax === false
                ? selectedGstCalTax === "interested"
                  ? igst
                    ? igst
                    : 0
                  : 0
                : 0
              : checkedTax === true
              ? selectedGstCalTax === "interested"
                ? igst
                  ? igst
                  : 0
                : 0
              : 0,
          comment: comment,
          crmReferenceId: null,
          hsnSacCode: hsnCode,
          cartChild: checkBoxMaking.map((c) => {
            const ch = items2.find((it) => it.id === c);
            return {
              lbrId: ch.id,
              price: ch.price,
              taxPercentage: ch.taxPercentage,
              hsncode: ch.hsncode,
              tax: ch.tax,
              title: ch.title,
              parentId: simpleSelect1,
            };
          }),
          cartImage: checkBoxImage.map((item) => {
            const ch = imgList.find((it) => it.id === item);
            return {
              imgId: ch.id,
              categoryId: ch.categoryId,
              parentId: ch.parentId,
              cropImageUrl: null,
              cropName: ch.cropName,
            };
          }),
          sizeCutOrFull:
            checkedFullSize === true
              ? checkedCutSize === true
                ? "3"
                : checkedFullSize === true
                ? "1"
                : "0"
              : checkedCutSize === true
              ? "2"
              : "0",
          sizeCountry: "",
          size1x8: checkedFullSize === true ? (size1x8 ? size1x8 : 0) : 0,
          size1x9: checkedCutSize === true ? (size1x9 ? size1x9 : 0) : 0,
          size1x10: checkedFullSize === true ? (size1x10 ? size1x10 : 0) : 0,
          size1x11: checkedCutSize === true ? (size1x11 ? size1x11 : 0) : 0,
          size1x12: checkedFullSize === true ? (size1x12 ? size1x12 : 0) : 0,
          size1x13: checkedCutSize === true ? (size1x13 ? size1x13 : 0) : 0,
          size1x14: checkedFullSize === true ? (size1x14 ? size1x14 : 0) : 0,
          size1x15: checkedCutSize === true ? (size1x15 ? size1x15 : 0) : 0,
          size2x0: checkedFullSize === true ? (size2x0 ? size2x0 : 0) : 0,
          size2x1: checkedCutSize === true ? (size2x1 ? size2x1 : 0) : 0,
          size2x2: checkedFullSize === true ? (size2x2 ? size2x2 : 0) : 0,
          size2x3: checkedCutSize === true ? (size2x3 ? size2x3 : 0) : 0,
          size2x4: checkedFullSize === true ? (size2x4 ? size2x4 : 0) : 0,
          size2x5: checkedCutSize === true ? (size2x5 ? size2x5 : 0) : 0,
          size2x6: checkedFullSize === true ? (size2x6 ? size2x6 : 0) : 0,
          size2x7: checkedCutSize === true ? (size2x7 ? size2x7 : 0) : 0,
          size2x8: checkedFullSize === true ? (size2x8 ? size2x8 : 0) : 0,
          size2x9: checkedCutSize === true ? (size2x9 ? size2x9 : 0) : 0,
          size2x10: checkedFullSize === true ? (size2x10 ? size2x10 : 0) : 0,
          size2x11: checkedCutSize === true ? (size2x11 ? size2x11 : 0) : 0,
          size2x12: checkedFullSize === true ? (size2x12 ? size2x12 : 0) : 0,
          size2x13: checkedCutSize === true ? (size2x13 ? size2x13 : 0) : 0,
          size2x14: checkedFullSize === true ? (size2x14 ? size2x14 : 0) : 0,
          size2x15: checkedCutSize === true ? (size2x15 ? size2x15 : 0) : 0,
          size3x0: checkedFullSize === true ? (size3x0 ? size3x0 : 0) : 0,
          size3x1: checkedCutSize === true ? (size3x1 ? size3x1 : 0) : 0,
          taxAbleAmount:
            selectedGstCal === "gst"
              ? ((includeProfit * (fullSize + cutSize)) /
                  (selectedGstCalTax === "interested"
                    ? interestedPer
                    : localPer)) *
                100
              : totalProfit * (fullSize + cutSize),
          taxType: selectedGstCalTax === "local" ? cgst : igst,
          taxCgst:
            selectedGstCal === "gst"
              ? checkedTax === false
                ? 0
                : checkedTax === true
                ? selectedGstCalTax === "local"
                  ? (((includeProfit * (fullSize + cutSize)) /
                      (selectedGstCalTax === "local"
                        ? localPer
                        : interestedPer)) *
                      (selectedGstCalTax === "local" ? local : interested)) /
                    2
                    ? (((includeProfit * (fullSize + cutSize)) /
                        (selectedGstCalTax === "local"
                          ? localPer
                          : interestedPer)) *
                        (selectedGstCalTax === "local" ? local : interested)) /
                      2
                    : 0
                  : 0
                : 0
              : selectedGstCalTax === "local"
              ? (totalProfit * (fullSize + cutSize) * local) / 100 / 2
              : 0,
          taxSgst:
            selectedGstCal === "gst"
              ? checkedTax === false
                ? 0
                : checkedTax === true
                ? selectedGstCalTax === "local"
                  ? (((includeProfit * (fullSize + cutSize)) /
                      (selectedGstCalTax === "local"
                        ? localPer
                        : interestedPer)) *
                      (selectedGstCalTax === "local" ? local : interested)) /
                    2
                    ? (((includeProfit * (fullSize + cutSize)) /
                        (selectedGstCalTax === "local"
                          ? localPer
                          : interestedPer)) *
                        (selectedGstCalTax === "local" ? local : interested)) /
                      2
                    : 0
                  : 0
                : 0
              : selectedGstCalTax === "local"
              ? (totalProfit * (fullSize + cutSize) * local) / 100 / 2
              : 0,
          taxIgst:
            selectedGstCal === "gst"
              ? checkedTax === false
                ? 0
                : checkedTax === true
                ? selectedGstCalTax === "interested"
                  ? ((includeProfit * (fullSize + cutSize)) /
                      (selectedGstCalTax === "interested"
                        ? interestedPer
                        : localPer)) *
                    (selectedGstCalTax === "local" ? local : interested)
                    ? ((includeProfit * (fullSize + cutSize)) /
                        (selectedGstCalTax === "interested"
                          ? interestedPer
                          : localPer)) *
                      (selectedGstCalTax === "local" ? local : interested)
                    : 0
                  : 0
                : 0
              : selectedGstCalTax === "interested"
              ? (totalProfit * (fullSize + cutSize) * interested) / 100
              : 0,
          taxTotal:
            selectedGstCal === "gst"
              ? selectedGstCalTax === "local"
                ? (((includeProfit * (fullSize + cutSize)) /
                    (selectedGstCalTax === "local"
                      ? localPer
                      : interestedPer)) *
                    (selectedGstCalTax === "local" ? local : interested)) /
                    2 +
                  (((includeProfit * (fullSize + cutSize)) /
                    (selectedGstCalTax === "local"
                      ? localPer
                      : interestedPer)) *
                    (selectedGstCalTax === "local" ? local : interested)) /
                    2
                : ((includeProfit * (fullSize + cutSize)) /
                    (selectedGstCalTax === "interested"
                      ? interestedPer
                      : localPer)) *
                  (selectedGstCalTax === "local" ? local : interested)
              : selectedGstCalTax === "local"
              ? (totalProfit * (fullSize + cutSize) * local) / 100
              : (totalProfit * (fullSize + cutSize) * interested) / 100,
          stock: false,
          sizeTotal: fullSize + cutSize,
        }),
      };

      fetch(localApi + `/cart/update/byCartId/${crtId}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log(data);
          if (data.message === "Data Updated Successfully !!!") {
            alertTypeOps("success");
            alertMsgOps("Succesfully !!!");
            setSubmitted(true);
          }
        });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    if (selectedPieceSets) {
      let platingPrice = selectedPieceSets?.pcs * selectedBox?.box;
      setPlatVal(platingPrice ? platingPrice.toFixed(2) : 0);
    }
    if (selectedBox) {
      let boxTotal = selectedBox?.price + selectedBox?.pkgCharge;
      setBoxVal(boxTotal);
    }
    if (metalGram) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (metalPrice) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (simpleSelectMetal) {
      let newMet =
        metalGram *
        selectedPieceSets?.pcs *
        selectedBox?.box *
        (metalPrice + metalDiff);
      setMetGramVal(newMet.toFixed(2));
    }
    if (packingOption === "Plastic Dibbi") {
      const ployTotals = plasticPrice * selectedBox?.box;
      setPackVal(ployTotals.toFixed(2));
    }
    if (packingOption === "Crystal Dibbi") {
      const crysTotals = crystalPrice * selectedBox?.box;
      setPackVal(crysTotals.toFixed(2));
    }
    if (packingOption === "Polythene PP") {
      const ployTotals = ployPrice * selectedBox?.box;
      setPackVal(ployTotals.toFixed(2));
    }
    // if (simpleSelectPloythene) {
    //   let ployTotal = ployPrice * selectedBox?.box;
    //   setPackVal(ployTotal.toFixed(2));
    //   // console.log("change", simpleSelectPloythene);
    // }
    // if (simpleSelectCrystal) {
    //   let crysTotal = crystalPrice * selectedBox?.box;
    //   setPackVal(crysTotal.toFixed(2));
    // }
    // if (simpleSelectPlastic) {
    //   let packTotal = plasticPrice * selectedBox?.box;
    //   setPackVal(packTotal.toFixed(2));
    // }
    if (checkBoxMaking) {
      let makPrice = makingPrice * selectedPieceSets?.pcs * selectedBox?.box;
      setMakVal(makPrice);
    }
  }, [
    selectedPieceSets,
    selectedBox,
    metalGram,
    metalPrice,
    simpleSelectMetal,
    checkBoxMaking,
    // simpleSelectPloythene,
    // simpleSelectCrystal,
    // simpleSelectPlastic,
    packingOption === "Plastic Dibbi",
    packingOption === "Crystal Dibbi",
    packingOption === "Polythene PP",
  ]);

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const mystyle = {
    position: "fixed",
    bottom: "10px",
    right: "30px",
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  var mixmakPrice = 0;
  var mixmetPrice = 0;
  var mixplatingPrice = 0;
  var lbrmetprice = 0;
  var mixmakPriceGst = 0;
  var mixplatPriceGst = 0;
  var mixmetPriceGst = 0;

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Update Client Cart</small>
                {newUpdateCart === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {simpleSelectClientErr || simpleCartItemErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    Select Cart Id
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer style={{ marginBottom: "2%" }}>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Updated Client Cart
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Company & Client Name
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    required={true}
                    placeholder="Select Company & Client Name..."
                    value={simpleSelectClient}
                    name="simpleSelectClient"
                    onChange={handleClient}
                    options={
                      itemClient &&
                      itemClient.map((c) => {
                        return {
                          label:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                          value:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                        };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Cart Number
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Cart Number..."
                    value={simpleCartItem}
                    onChange={handleCartItem}
                    options={
                      cartItem &&
                      cartItem.map((c) => {
                        return {
                          label: c.id + "--> Ready Gram : " + c.readyGram,
                          value: c.id + "--> Ready Gram : " + c.readyGram,
                        };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Product Id...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectProd}
                      onChange={handleSimpleProd}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Product Id...
                      </MenuItem>
                      {itemProd &&
                        itemProd.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.id}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Name...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category Name...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                      disabled={true}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaRing />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Piece/Sets</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {itemSets &&
                  itemSets.map((sets) => (
                    <GridItem xs={12} sm={4} md={3} key={sets.id}>
                      <FormControlLabel
                        key={sets.id}
                        control={
                          <Radio
                            // checked={selectedPieceSets && selectedPieceSets
                            //     ? selectedPieceSets === sets.id
                            //     : sets.active === 1}
                            // checked={
                            //   selectedPieceSets && selectedPieceSets.id || selectedPieceSets.setsId
                            //     ? selectedPieceSets?.id === sets?.id || selectedPieceSets?.setsId === sets?.id
                            //     : ""
                            // }
                            checked={
                              selectedPieceSets && selectedPieceSets.setsId
                                ? selectedPieceSets?.setsId === sets?.id
                                : selectedPieceSets.id
                                ? selectedPieceSets?.id === sets?.id
                                : ""
                            }
                            onChange={(e) => {
                              setSelectedPieceSets(sets);
                            }}
                            value={sets.id}
                            name="radioButton"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        // label={sets.setdesc}
                      />
                      <label
                        style={{
                          // color:
                          //   sets?.id === selectedPieceSets?.setsId ? "#d81b60" : sets?.id === selectedPieceSets?.id ? "#d81b60" : "",
                          color:
                            selectedPieceSets?.setsId === sets?.id
                              ? "#d81b60"
                              : selectedPieceSets?.id === sets?.id
                              ? "#d81b60"
                              : "",
                        }}
                      >
                        {sets.setDesc}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaBox />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Box + Labour ₹ {boxVal}/-</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {itemBox &&
                  itemBox.map((box) => (
                    <GridItem xs={12} sm={6} md={3} key={box.id}>
                      <FormControlLabel
                        key={box.id}
                        control={
                          <Radio
                            // checked={
                            //     selectedBox &&
                            //         selectedBox ? selectedBox === box.id
                            //         : box.active === 1
                            // }
                            // checked={
                            //   (selectedBox && selectedBox.id) ||
                            //   selectedBox.boxId
                            //     ? selectedBox?.id === box?.id ||
                            //       selectedBox?.boxId === box?.id
                            //     : box?.active === 1
                            // }
                            checked={
                              selectedBox && selectedBox.boxId
                                ? selectedBox?.boxId === box?.id
                                : selectedBox.id
                                ? selectedBox?.id === box?.id
                                : ""
                            }
                            onChange={(e) => {
                              setSelectedBox(box);
                              // console.log(selectedBox);
                              if (box?.id) {
                                let boxTotal = box?.price + box?.pkgCharge;
                                setBoxVal(boxTotal);
                              }
                            }}
                            value={box.id}
                            name="radio button demo"
                            aria-label="A"
                            icon={
                              <FiberManualRecord
                                className={classesRadio.radioUnchecked}
                              />
                            }
                            checkedIcon={
                              <FiberManualRecord
                                className={classesRadio.radioChecked}
                              />
                            }
                            classes={{
                              checked: classesRadio.radioRose,
                              root: classesRadio.radioRoot,
                            }}
                          />
                        }
                        classes={{
                          label: classesRadio.label,
                          root: classesRadio.labelRoot,
                        }}
                        // label={box.box + " " + box.brandname}
                      />
                      <label
                        style={{
                          // color: box?.id === selectedBox?.id ? "#d81b60" : "",
                          color:
                            box?.id === selectedBox?.boxId
                              ? "#d81b60"
                              : box?.id === selectedBox?.id
                              ? "#d81b60"
                              : "",
                        }}
                      >
                        {box.box + " " + box.brandName}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <FaUsers />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Making Charge Per (Box : ₹ {makVal.toFixed(2)}) </small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {items2 &&
                  items2.map((making) => (
                    <GridItem xs={12} sm={6} md={4} key={making.id}>
                      <FormControlLabel
                        key={making.id}
                        control={
                          <Checkbox
                            tabIndex={-1}
                            checked={checkBoxMaking.includes(making.id)}
                            name={making.title}
                            onChange={(e) => handleCheckBoxMaking(e, making)}
                            checkedIcon={
                              <Check className={classCheck.checkedIcon} />
                            }
                            icon={
                              <Check className={classCheck.uncheckedIcon} />
                            }
                            classes={{
                              checked: classCheck.checkedRose,
                              root: classCheck.checkRoot,
                            }}
                          />
                        }
                        classes={{
                          label:
                            classCheck.label +
                            (makingCheckboxMaking === "error"
                              ? " " + classCheck.labelError
                              : ""),
                        }}
                        // label={making.description + "> " + making.price}
                      />
                      <label
                        style={{
                          color: checkBoxMaking.includes(making)
                            ? "#d81b60"
                            : "",
                        }}
                      >
                        {making.title + "> " + making.price}
                      </label>
                    </GridItem>
                  ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiWireCoil />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>
                  {" "}
                  Metal & Ready Gram Per Piece (Box ₹ {metGramVal})
                </small>
              </h3>
            </CardHeader>
            <CardBody>
              <h5 className={classes.cardIconTitle}>
                Metal Price ₹{" "}
                {simpleSelectMetal
                  ? (metals ? metals : metalPrice) + " + " + metalDiff
                  : ""}
              </h5>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Metal
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelectMetal}
                      onChange={handleSimpleMetal}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Metal
                      </MenuItem>
                      {metal &&
                        metal.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title +
                              "> " +
                              item.price +
                              " + " +
                              item.different}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Metal"
                    id="metals"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metals"
                    value={metals}
                    onChange={(e) => {
                      const { value } = e.target;
                      setMetals(value);
                      if (value) {
                        let newMet =
                          metalGram *
                          selectedPieceSets?.pcs *
                          selectedBox?.box *
                          (parseInt(value) + metalDiff);
                        setMetGramVal(newMet.toFixed(2));
                      } else {
                        let newMet =
                          metalGram *
                          selectedPieceSets?.pcs *
                          selectedBox?.box *
                          (metalPrice + metalDiff);
                        setMetGramVal(newMet.toFixed(2));
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Ready Gram"
                    id="readyGram"
                    // inputProps={{
                    //   placeholder: "Ready Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="readyGram"
                    value={readyGram}
                    onChange={(e) => setReadyGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Metal Gram"
                    id="metalGram"
                    // inputProps={{
                    //   placeholder: "Metal Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalGram"
                    value={metalGram}
                    onChange={(e) => {
                      const { value } = e.target;
                      setMetalGram(value);
                      if (value) {
                        let newMet =
                          value *
                          selectedPieceSets?.pcs *
                          selectedBox?.box *
                          (metalPrice + metalDiff);
                        setMetGramVal(newMet.toFixed(2));
                      }
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Extra S Gram"
                    id="metalSGram"
                    // inputProps={{
                    //   placeholder: "Metal S Gram",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="metalSGram"
                    value={metalSGram}
                    onChange={(e) => setMetalSGram(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Thaw Golwire"
                    id="golwireThaw"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="golwireThaw"
                    value={golwireThaw}
                    onChange={(e) => setGolwireThaw(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <GiBoxUnpacking />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small> Packing Option (Box ₹ {packVal})</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Plastic Dibbi"}
                          onChange={handlePackingOption}
                          value="Plastic Dibbi"
                          name="Plastic Dibbi"
                          aria-label="Plastic Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Plastic Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Plastic Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Plastic Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Plastic
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPlastic}
                        onChange={handleSimplePlastic}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Plastic
                        </MenuItem>
                        {plastic &&
                          plastic.map((plas) => (
                            <MenuItem
                              key={plas.id}
                              value={plas.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {plas.pkgdesc + "> " + plas.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Crystal Dibbi"}
                          onChange={handlePackingOption}
                          value="Crystal Dibbi"
                          name="Crystal Dibbi"
                          aria-label="Crystal Dibbi"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Crystal Dibbi"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Crystal Dibbi" ? "#d81b60" : "",
                      }}
                    >
                      Crystal Dibbi
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Crystal
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectCrystal}
                        onChange={handleSimpleCrystal}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Crystal
                        </MenuItem>
                        {crystal &&
                          crystal.map((crys) => (
                            <MenuItem
                              key={crys.id}
                              value={crys.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {crys.pkgdesc + "> " + crys.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={packingOption === "Polythene PP"}
                          onChange={handlePackingOption}
                          value="Polythene PP"
                          name="Polythene PP"
                          aria-label="Polythene PP"
                          icon={
                            <FiberManualRecord
                              className={classesRadio.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classesRadio.radioChecked}
                            />
                          }
                          classes={{
                            checked: classesRadio.radioRose,
                            root: classesRadio.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classesRadio.label,
                        root: classesRadio.labelRoot,
                      }}
                      // label="Polythene PP"
                    />
                    <label
                      style={{
                        color:
                          packingOption === "Polythene PP" ? "#d81b60" : "",
                      }}
                    >
                      Polythene PP
                    </label>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl fullWidth className={classe.selectFormControl}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classe.selectLabel}
                      >
                        Select Polythene
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classe.selectMenu,
                        }}
                        classes={{
                          select: classe.select,
                        }}
                        value={simpleSelectPloythene}
                        onChange={handleSimplePloythene}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classe.selectMenuItem,
                          }}
                        >
                          Select Polythene
                        </MenuItem>
                        {ploythene &&
                          ploythene.map((ploy) => (
                            <MenuItem
                              key={ploy.id}
                              value={ploy.id}
                              classes={{
                                root: classe.selectMenuItem,
                                selected: classe.selectMenuItemSelected,
                              }}
                            >
                              {ploy.pkgdesc + "> " + ploy.price}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineCalculator />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Price Calculation</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "gst"}
                        onChange={handleChangeGstCal}
                        value="gst"
                        name="gst"
                        aria-label="GST"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    // label="GST"
                  />
                  <label
                    style={{ color: selectedGstCal === "gst" ? "#d81b60" : "" }}
                  >
                    TaxInclude ({tax})
                  </label>
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  {/* <GridItem xs={12} sm={12} md={12}> */}
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedGstCal === "cash"}
                        onClick={handleChangeGstCal}
                        value="cash"
                        name="cash"
                        aria-label="Cash"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    // label="CASH"
                  />
                  <label
                    style={{
                      color: selectedGstCal === "cash" ? "#d81b60" : "",
                    }}
                  >
                    CASH
                  </label>
                </GridItem>
                {/* <GridItem xs={6} sm={6} md={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checkedA}
                        onChange={(event) => setCheckedA(event.target.checked)}
                        value="checkedA"
                        classes={{
                          switchBase: classe.switchBase,
                          checked: classes.switchChecked,
                          thumb: classe.switchIcon,
                          track: classe.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classe.label,
                    }}
                    label={
                      checkedA === false ? (
                        "Offline"
                      ) : (
                        <span
                          style={{ color: checkedA === true ? "#d81b60" : "" }}
                        >
                          Online
                        </span>
                      )
                    }
                  />
                </GridItem> */}
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>Making Charge</small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  {" "}
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeGST
                        : totalCash}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeSets
                        : sets}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              <GridContainer style={{ border: "1px solid #eeeeee" }}>
                <GridItem xs={12} sm={4} ms={4}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={selectedProfit === "profit"}
                        onClick={handleProfit}
                        value="profit"
                        name="profit"
                        aria-label="profit"
                        icon={
                          <FiberManualRecord
                            className={classesRadio.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord
                            className={classesRadio.radioChecked}
                          />
                        }
                        classes={{
                          checked: classesRadio.radioRose,
                          root: classesRadio.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classesRadio.label,
                      root: classesRadio.labelRoot,
                    }}
                    label={`Profit ${userObj.profit}%`}
                  />
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfit
                        : totalProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
                <GridItem xs={6} sm={4} ms={4}>
                  <h5 className={classes.cardIconTitle}>
                    <small>
                      {" "}
                      Sets ₹{" "}
                      {selectedGstCal && selectedGstCal === "gst"
                        ? includeProfitSet
                        : setProfit}
                      /-
                    </small>
                  </h5>
                </GridItem>
              </GridContainer>
              <br />
              {selectedGstCal === "gst" ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedTax}
                          onChange={(event) =>
                            setCheckedTax(event.target.checked)
                          }
                          value="checkedTax"
                          disabled={true}
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedTax === false ? (
                          <span>Tax Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedTax === true ? "#d81b60" : "",
                            }}
                          >
                            Tax Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedTax === true ? (
                    <>
                      <GridItem xs={5} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedGstCalTax === "local"}
                              onChange={handleChangeGstCalTax}
                              value="local"
                              name="local"
                              aria-label="LOCAL"
                              icon={
                                <FiberManualRecord
                                  className={classesRadio.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classesRadio.radioChecked}
                                />
                              }
                              classes={{
                                checked: classesRadio.radioRose,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                            root: classesRadio.labelRoot,
                          }}
                          // label="GST"
                        />
                        <label
                          style={{
                            color:
                              selectedGstCalTax === "local" ? "#d81b60" : "",
                          }}
                        >
                          LOCAL
                        </label>
                      </GridItem>
                      <GridItem xs={5} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedGstCalTax === "interested"}
                              onClick={handleChangeGstCalTax}
                              value="interested"
                              name="interested"
                              aria-label="INTERESTED"
                              icon={
                                <FiberManualRecord
                                  className={classesRadio.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classesRadio.radioChecked}
                                />
                              }
                              classes={{
                                checked: classesRadio.radioRose,
                                root: classesRadio.radioRoot,
                              }}
                            />
                          }
                          classes={{
                            label: classesRadio.label,
                            root: classesRadio.labelRoot,
                          }}
                          // label="CASH"
                        />
                        <label
                          style={{
                            color:
                              selectedGstCalTax === "interested"
                                ? "#d81b60"
                                : "",
                          }}
                        >
                          INTERESTED
                        </label>
                      </GridItem>
                      {selectedGstCalTax === "local" ? (
                        <>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="CGST @9%..."
                              id="cgst"
                              inputProps={{
                                placeholder: "CGST @9%...",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              name="cgst"
                              value={cgst}
                              onChange={(e) => {
                                setCgst(e.target.value);
                                // setCgstErr("");
                              }}
                              required={true}
                            />
                            {/* <span style={{ color: "red" }}>
                                        {gstErr}
                                      </span> */}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="SGST @9%..."
                              id="sgst"
                              inputProps={{
                                placeholder: "SGST @9%...",
                              }}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              name="sgst"
                              value={sgst}
                              onChange={(e) => {
                                setSgst(e.target.value);
                                // setSgstErr("");
                              }}
                              required={true}
                            />
                            {/* <span style={{ color: "red" }}>
                                        {gstErr}
                                      </span> */}
                          </GridItem>
                        </>
                      ) : (
                        ""
                      )}
                      {selectedGstCalTax === "interested" ? (
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="IGST @18%..."
                            id="igst"
                            inputProps={{
                              placeholder: "IGST @18%...",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                            name="igst"
                            value={igst}
                            onChange={(e) => {
                              setIgst(e.target.value);
                              // setGstErr("");
                            }}
                            required={true}
                          />
                          {/* <span style={{ color: "red" }}>
                                        {gstErr}
                                      </span> */}
                        </GridItem>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </GridContainer>
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Size</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedFullSize}
                          onChange={(event) =>
                            setCheckedFullSize(event.target.checked)
                          }
                          value="checkedFullSize"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedFullSize === false ? (
                          <span>Full Size Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedFullSize === true ? "#d81b60" : "",
                            }}
                          >
                            Full Size Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedFullSize === true ? (
                    // <Card>
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x8"
                          id="1x8"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x8}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x8(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x10"
                          id="1x10"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x10}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x10(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x12"
                          id="1x12"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x12}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x12(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x14"
                          id="1x14"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x14}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x14(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x0"
                          id="2x0"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x0}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x0(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>

                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x2"
                          id="2x2"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x2}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x2(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x4"
                          id="2x4"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x4}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x4(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x6"
                          id="2x6"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x6}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x6(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x8"
                          id="2x8"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x8}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x8(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x10"
                          id="2x10"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x10}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x10(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x12"
                          id="2x12"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x12}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x12(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x14"
                          id="2x14"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x14}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x14(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="3x0"
                          id="3x0"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size3x0}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize3x0(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Full Size Total {fullSize}</h4>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    // </Card>
                    ""
                  )}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checkedCutSize}
                          onChange={(event) =>
                            setCheckedCutSize(event.target.checked)
                          }
                          value="checkedCutSize"
                          classes={{
                            switchBase: classe.switchBase,
                            checked: classes.switchChecked,
                            thumb: classe.switchIcon,
                            track: classe.switchBar,
                          }}
                        />
                      }
                      classes={{
                        label: classe.label,
                      }}
                      label={
                        checkedCutSize === false ? (
                          <span>Cut Size Disable</span>
                        ) : (
                          <span
                            style={{
                              color: checkedCutSize === true ? "#d81b60" : "",
                            }}
                          >
                            Cut Size Enable
                          </span>
                        )
                      }
                    />
                  </GridItem>
                  {checkedCutSize === true ? (
                    <GridContainer>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x9"
                          id="1x9"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={size1x9}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x9(value);
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x11"
                          id="1x11"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x11}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x11(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x13"
                          id="1x13"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x13}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x13(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="1x15"
                          id="1x15"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size1x15}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize1x15(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x1"
                          id="2x1"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x1}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x1(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x3"
                          id="2x3"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x3}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x3(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x5"
                          id="2x5"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x5}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x5(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x7"
                          id="2x7"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x7}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x7(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="2x9"
                          id="2x9"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x9}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x9(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x11"
                          id="2x11"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x11}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x11(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x13"
                          id="2x13"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x13}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x13(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="2x15"
                          id="2x15"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size2x15}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize2x15(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3} sm={3} md={3}>
                        <CustomInput
                          labelText="3x1"
                          id="3x1"
                          number
                          inputProps={{
                            placeholder: totalPiece,
                          }}
                          value={size3x1}
                          onChange={(e) => {
                            const { value } = e.target;
                            setSize3x1(value);
                          }}
                          formControlProps={{
                            fullWidth: true,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>Cut Size Total {cutSize}</h4>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <h4>Full & Cut Size Total {fullSize + cutSize}</h4>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiFile />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Product Detail</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Item Code"
                    id="prodItemCode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodItemCode"
                    value={prodItemCode}
                    onChange={(e) => setProdItemCode(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Manufacture Details"
                    id="prodMfgDetails"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodMfgDetails"
                    value={prodMfgDetails}
                    onChange={(e) => setProdMfgDetails(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Product Description"
                    id="prodDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodDescription"
                    value={prodDescription}
                    onChange={(e) => setProdDescription(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Description"
                    id="cartonDescription"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonDescription"
                    value={cartonDescription}
                    onChange={(e) => setCartonDescription(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Quantity"
                    id="cartonQuantity"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonQuantity"
                    value={cartonQuantity}
                    onChange={(e) => setCartonQuantity(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Carton Packing Charge"
                    id="cartonPackingCharge"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="cartonPackingCharge"
                    value={cartonPackingCharge}
                    onChange={(e) => setCartonPackingCharge(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="HSN/SAC CODE"
                    id="hsnsaccode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="hsnCode"
                    value={hsnCode}
                    onChange={(e) => setHsnCode(e.target.value)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Comment"
                    id="comment"
                    inputProps={{
                      // placeholder: "Comment...",
                      multiline: true,
                      rows: 5,
                    }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BsImages />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Select Design Images</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {imgList &&
                  imgList.map((i) => (
                    <GridItem xs={3} sm={2} md={2} key={i.id}>
                      <div className="fileinput text-center">
                        <div
                          className={
                            "thumbnail" + (avatar ? " img-circle" : "")
                          }
                        >
                          <img src={i.cpanelPath} alt="..." />
                        </div>
                      </div>
                      <br />
                      <div>
                        <span style={{ marginRight: "5px" }}>{i.id}</span>
                        <FormControlLabel
                          key={i.id}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              checked={checkBoxImage.includes(i.id)}
                              name={i.cropName}
                              onChange={(e) => {
                                handleCheckBoxImage(e, i);
                              }}
                              checkedIcon={
                                <Check className={classCheck.checkedIcon} />
                              }
                              icon={
                                <Check className={classCheck.uncheckedIcon} />
                              }
                              classes={{
                                checked: classCheck.checkedRose,
                                root: classCheck.checkRoot,
                              }}
                            />
                          }
                          classes={{
                            label:
                              classCheck.label +
                              (makingCheckboxImage === "error"
                                ? " " + classCheck.labelError
                                : ""),
                          }}
                          // label={i.id}
                        />
                      </div>
                    </GridItem>
                  ))}
                {newImage === true ? (
                  <GridContainer>
                    <GridItem md={12} sm={12} xs={12}>
                      <Button
                        color="rose"
                        simple
                        onClick={() => setNewImage(false)}
                      >
                        see More
                      </Button>
                    </GridItem>
                  </GridContainer>
                ) : (
                  <GridContainer>
                    <GridItem md={12} sm={12} xs={12}>
                      <Button
                        simple
                        color="rose"
                        onClick={() => setNewImage(true)}
                      >
                        See More
                      </Button>
                    </GridItem>
                  </GridContainer>
                )}
                {newImage === true ? (
                  <>
                    {imgListMore &&
                      imgListMore.map((i) => (
                        <GridItem xs={3} sm={2} md={2} key={i.id}>
                          <div className="fileinput text-center">
                            <div
                              className={
                                "thumbnail" + (avatar ? " img-circle" : "")
                              }
                            >
                              {/* <img
                                src={i.cropImageUrl + "?id=" + i.id}
                                alt="..."
                              /> */}
                              <img src={i.cpanelPath} alt="..." />
                            </div>
                          </div>
                          <br />
                          <div>
                            <span style={{ marginRight: "5px" }}>{i.id}</span>
                            <FormControlLabel
                              key={i.id}
                              control={
                                <Checkbox
                                  tabIndex={-1}
                                  checked={checkBoxImage.includes(i.id)}
                                  name={i.cropName}
                                  onChange={(e) => {
                                    handleCheckBoxImage(e, i);
                                  }}
                                  checkedIcon={
                                    <Check className={classCheck.checkedIcon} />
                                  }
                                  icon={
                                    <Check
                                      className={classCheck.uncheckedIcon}
                                    />
                                  }
                                  classes={{
                                    checked: classCheck.checkedRose,
                                    root: classCheck.checkRoot,
                                  }}
                                />
                              }
                              classes={{
                                label:
                                  classCheck.label +
                                  (makingCheckboxImage === "error"
                                    ? " " + classCheck.labelError
                                    : ""),
                              }}
                              // label={i.id}
                            />
                          </div>
                        </GridItem>
                      ))}
                  </>
                ) : (
                  ""
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <div style={mystyle}>
        {/* <Button
          color="rose"
          className={classes.updateProfileButton}
          onClick={SubmitUpdateProduct}
        >
          Save
        </Button> */}
        {btn === true ? (
          <Button className={classes.updateProfileButton}>Update</Button>
        ) : (
          <Button
            color="rose"
            onClick={SubmitUpdateProduct}
            className={classes.updateProfileButton}
          >
            Update
          </Button>
        )}
      </div>
    </div>
  );
}

UpdateCart.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
