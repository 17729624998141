import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Compressor from "compressorjs";
import { dataURLtoFile } from "utils/dataURLToFile";
import { catApi } from "utils/imgApi";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import { localApi, localApiImg } from "utils/imgApi";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import style from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import logo from "assets/img/apple-admin-icon.png";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleSweet = makeStyles(styleSweet);

export default function AddImage(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [simpleSelect1Err, setSimpleSelect1Err] = React.useState("");
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [newImage, setNewImage] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classSweet = useStyleSweet();

  const adminObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const [file, setFile] = React.useState(null);
  const [fileErr, setFileErr] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? "" : ""
  );
  let fileInput = React.createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setFileErr("");
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? "" : "");
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  async function getCat() {
    const api = localApi + `/category/list/byAdminId/${adminObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItems(getResult);
  }

  useEffect(() => {
    getCat();
    // catApi().then((res) => setItems(res));
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id === value);
    setSimpleSelect(value);
    setGetId(item.id);
    const response = await axios.get(
      localApi + `/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
    setSimpleSelectErr("");
  };

  const handleSimple1 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id === value);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
    // console.log(imgApi + "" + itemm.image);
    setSimpleSelect1Err("");
  };

  const SubmitAddImage = (e) => {
    setNewImage(true);
    let errorCount = 0;
    if (simpleSelect === "" || !simpleSelect) {
      errorCount++;
      setSimpleSelectErr("Select Category");
    }
    if (simpleSelect1 === "" || !simpleSelect1) {
      errorCount++;
      setSimpleSelect1Err("Select Product Size");
    }
    if (file === "" || !file) {
      errorCount++;
      setFileErr("of fix size (atleast width-280, height-336 pixel)");
    }
    if (errorCount === 0) {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          const compressFile = new File([compressedResult], `${file?.name}`, {
            type: compressedResult.type,
          });
          // console.log(compressFile);
          setBtn(true);

          let fData = new FormData();
          fData.append("cropImage", compressFile);
          fData.append(
            "data",
            new Blob(
              [
                JSON.stringify({
                  adminId: adminObj.id,
                  categoryId: getId,
                  parentId: getcatId,
                }),
              ],
              {
                type: "application/json",
              }
            )
          );

          axios
            .post(localApi + "/image/addImage", fData, {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            })
            .then((response) => {
              // console.log(response.data);
              if (response.data.message === "Image Inserted Successfully") {
                alertTypeOps("success");
                alertMsgOps("Succesfully !!!");
                setSubmitted(true);
              }
            });
        },
      });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // console.log("Value Changed")
    setSimpleSelect1("");
    setGetcatId("");
  }, [simpleSelect]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={8} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <BiEdit />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Add Fix Image</small>
                {newImage === true ? (
                  <small
                    style={{
                      fontSize: "12px",
                      color: "red",
                      marginLeft: "10px",
                    }}
                  >
                    {simpleSelectErr || simpleSelect1Err || fileErr}
                  </small>
                ) : (
                  <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                    of fix size (atleast width-280, height-336 pixel)
                  </small>
                )}
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Added Fix Image
                  </SweetAlert>
                ) : null}{" "}
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Category Title...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect}
                      onChange={handleSimple}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Category title...
                      </MenuItem>
                      {items &&
                        items.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classe.selectFormControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classe.selectLabel}
                    >
                      Select Bangles Size-MM...
                    </InputLabel>

                    <Select
                      MenuProps={{
                        className: classe.selectMenu,
                      }}
                      classes={{
                        select: classe.select,
                      }}
                      value={simpleSelect1}
                      onChange={handleSimple1}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classe.selectMenuItem,
                        }}
                      >
                        Select Bangles Size-MM...
                      </MenuItem>
                      {items1 &&
                        items1.map((item1) => (
                          <MenuItem
                            key={item1.id}
                            value={item1.id}
                            classes={{
                              root: classe.selectMenuItem,
                              selected: classe.selectMenuItemSelected,
                            }}
                          >
                            {item1.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div className="fileinput text-center">
                    <input
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInput}
                    />

                    <div
                      className={"thumbnail" + (avatar ? " img-circle" : "")}
                    >
                      {imagePreviewUrl && (
                        <img src={imagePreviewUrl} alt="..." />
                      )}
                    </div>
                    <GridContainer>
                      {file === null ? (
                        <Button
                          color="rose"
                          round
                          {...addButtonProps}
                          onClick={() => handleClick()}
                        >
                          {avatar ? "Add Photo" : "Add Image"}
                        </Button>
                      ) : (
                        <span>
                          <Button
                            color="rose"
                            round
                            {...changeButtonProps}
                            onClick={() => handleClick()}
                          >
                            Change
                          </Button>
                          {avatar ? <br /> : null}
                          <Button
                            color="rose"
                            round
                            {...removeButtonProps}
                            onClick={() => handleRemove()}
                          >
                            <i className="fas fa-times" /> Remove
                          </Button>
                        </span>
                      )}
                    </GridContainer>
                  </div>
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                onClick={SubmitAddImage}
                className={classes.updateProfileButton}
              >
                Add
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Add</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={SubmitAddImage}
                  className={classes.updateProfileButton}
                >
                  Add
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

AddImage.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
