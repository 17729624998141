import React, { useEffect } from "react";
import axios from "axios";
import Selects from "react-select";
import PropTypes from "prop-types";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { localApi } from "utils/imgApi";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyl = makeStyles(styDrop);
const useStyleSweet = makeStyles(styleSweet);

export default function DeleteCart(props) {
  const [client, setClient] = React.useState([]);
  const [cartItem, setCartItem] = React.useState([]);
  const [simpleCartItem, setSimpleCartItem] = React.useState("");
  const [prodItemCode, setProdItemCode] = React.useState("");
  const [images, setImages] = React.useState("");
  const [clientId, setClientId] = React.useState("");
  const [clientid, setClientid] = React.useState("");
  const [cartId, setCartId] = React.useState("");

  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyl();
  const classSweet = useStyleSweet();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  async function getClient() {
    const api =
      localApi + `/clientGroup/list/byAdminId/${userObj && userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setClient(getResult && getResult.filter((f) => f.type != "Crm"));
    // console.log(getResult.user);
  }

  useEffect(() => {
    getClient();
  }, []);

  const handleBoots = (bootselect) => {
    // const { value } = e.target;
    if (bootselect.value) {
      let newSpli = bootselect.value.split("-");
      const ids = client.find((f) => f.mobile == newSpli[1]);
      // console.log(ids);
      setClientid(ids && ids);
      axios
        .get(
          localApi +
            `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.id}/${ids.id}?active=0`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          setCartItem(res && res.data);
          //   console.log(res.data);
        });
    }
    setClientId(bootselect);
  };

  const handleCartItem = (simpleCartItem) => {
    // setSimpleProductSelect(e.target.value);
    setSimpleCartItem(simpleCartItem);
    if (simpleCartItem.value) {
      let newCartItem = simpleCartItem.value.split("-");
      const itemz = cartItem.find((itemz) => itemz.id == newCartItem[0]);
      // console.log(itemz);
      setCartId(itemz.id);
      setProdItemCode(itemz.itemCodeNumber);
      setImages(
        itemz.cartImage[0].cpanelPath
      );
    }
  };

  const handleRemove = () => {
    setSubmitteds(true);
  };

  const handleConfirm = () => {
    setBtn(true);
    fetch(localApi + `/cart/delete/byAdminAndCartId/${userObj.id}/${cartId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  useEffect(() => {
    setSimpleCartItem("");
    setProdItemCode("");
    setImages("");
  }, [clientId]);

  return (
    <div>
      <GridContainer justify="center" alignItems="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <DeleteForeverIcon />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>Delete Client Cart</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer style={{ marginBottom: "2%" }}>
                {submitted ? (
                  <SweetAlert
                    type={alertType}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={alertMsg}
                    onConfirm={() => hideAlert()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  />
                ) : null}
                {submitteds ? (
                  <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "100px" }}
                    title="Are you sure?"
                    onConfirm={handleConfirm}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                    cancelBtnCssClass={
                      classSweet.button + " " + classSweet.danger
                    }
                    confirmBtnText="Yes, delete it!"
                    cancelBtnText="Cancel"
                    showCancel
                  />
                ) : null}

                {submittedConfirm ? (
                  <SweetAlert
                    success
                    style={{ display: "block", marginTop: "100px" }}
                    title="Deleted!"
                    onConfirm={() => hideAlerts()}
                    onCancel={() => hideAlerts()}
                    confirmBtnCssClass={
                      classSweet.button + " " + classSweet.success
                    }
                  >
                    Your imaginary cart has been deleted.
                  </SweetAlert>
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <label>Select Company & Client Name</label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Company & Client Name..."
                    value={clientId}
                    onChange={handleBoots}
                    options={
                      client &&
                      client.map((c) => {
                        return {
                          label:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                          value:
                            c && c.company
                              ? c.company + "-" + c.mobile
                              : c.firstname + " " + c.lastname + "-" + c.mobile,
                        };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <label>Select Cart Number</label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Cart Number..."
                    value={simpleCartItem}
                    onChange={handleCartItem}
                    options={
                      cartItem &&
                      cartItem.map((c) => {
                        return {
                          label: c.id + "--> Ready Gram : " + c.readyGram,
                          value: c.id + "--> Ready Gram : " + c.readyGram,
                        };
                      })
                    }
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Item Code"
                    id="prodItemCode"
                    // inputProps={{
                    //   placeholder: "Golwire Thaw",
                    // }}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    name="prodItemCode"
                    value={prodItemCode}
                    onChange={(e) => setProdItemCode(e.target.value)}
                    disabled={true}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "30px",
                    }}
                  >
                    {simpleCartItem ? (
                      <img
                        src={images}
                        style={{ width: "150px", height: "150px" }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </GridItem>
              </GridContainer>
              {/* <Button
                color="rose"
                className={classes.updateProfileButton}
                onClick={handleRemove}
              >
                Delete
              </Button> */}
              {btn === true ? (
                <Button className={classes.updateProfileButton}>Delete</Button>
              ) : (
                <Button
                  color="rose"
                  onClick={handleRemove}
                  className={classes.updateProfileButton}
                >
                  Delete
                </Button>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

DeleteCart.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
