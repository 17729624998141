import React, { useEffect } from "react";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { localApi, catApi } from "utils/imgApi";
import { BiFile } from "react-icons/bi";
import { makeStyles } from "@material-ui/core/styles";
import Selects from "react-select";
import { AiOutlineFolderView } from "react-icons/ai";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import CornerRibbon from "react-corner-ribbon";
import InputLabel from "@material-ui/core/InputLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import { FaBox, FaRing, FaUsers } from "react-icons/fa";
import { GiWireCoil, GiBoxUnpacking } from "react-icons/gi";
import { BsImages } from "react-icons/bs";
import { AiOutlineCalculator } from "react-icons/ai";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styRadio from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styCheckbox from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styHoever from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import { credentials } from "utils/imgApi";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(styRadio);
const useStyl = makeStyles(styDrop);
const useSty = makeStyles(styCheckbox);
const useStyleSweet = makeStyles(styleSweet);
const useHoever = makeStyles(styHoever);

export default function ViewStockProduct(props) {
  const [category, setCategory] = React.useState([]);
  const [categoryMM, setCategoryMM] = React.useState([]);
  const [itemProd, setItemProd] = React.useState([]);
  const [categoryName, setCategoryName] = React.useState("");
  const [categorySize, setCategorySize] = React.useState("");
  const [catItem, setCatItem] = React.useState("");
  const [catMMItem, setCatMMItem] = React.useState("");
  const [cartId, setCartId] = React.useState();

  const [submitted, setSubmitted] = React.useState(false);
  const [submitteds, setSubmitteds] = React.useState(false);
  const [submittedConfirm, setSubmittedConfirm] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const classes = useStyles();
  const classesRadio = useStyle();
  const classe = useStyl();
  const classCheck = useSty();
  const classSweet = useStyleSweet();
  const classeHover = useHoever();
  const history = useHistory();

  let { avatar } = props;

  const { state } = useLocation();

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  const totalPiece = "18";
  //   Math.ceil("864" / sets.pcs / box.box);

  useEffect(() => {
    let newSpli = state && state ? state.split(",") : "";
    // console.log(newSpli);

    axios
      .get(localApi + `/category/findById/${newSpli && newSpli[0]}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        const g = { label: res.data.title, value: res.data.title };
        setCategoryName(g);
        setCatItem(res.data);

        // console.log(res.data);

        // setCategoryMM(res.data.parent)
      });

    axios
      .get(localApi + `/parent/list/byCategoryId/${newSpli && newSpli[0]}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        setCategoryMM(res.data);
      });

    axios
      .get(localApi + `/parent/findById/${newSpli && newSpli[1]}`, {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      })
      .then((res) => {
        // console.log(res.data)
        const g = { label: res.data.title, value: res.data.title };
        // console.log(g);
        setCategorySize(g);
        setCatMMItem(res.data);
      });

    axios
      .get(
        localApi +
          `/product/list/byAdminAndCategoryAndParentId/${
            userObj && userObj.id
          }/${newSpli && newSpli[0]}/${newSpli && newSpli[1]}`,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      )
      .then((res) => {
        const pStock = res.data.filter((f) => f.stockSize.length === 1);
        //   console.log(pStock);
        setItemProd(pStock && pStock);
      });
  }, []);

  async function getCat() {
    const api = localApi + `/category/list/byAdminId/${userObj.id}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setCategory(getResult);
  }

  useEffect(() => {
    getCat();
  }, []);

  const handleCategoryName = async (categoryName) => {
    setCategoryName(categoryName);
    if (categoryName.value) {
      const catNameItem = category.find((f) => f.title == categoryName.value);

      const response = await axios.get(
        localApi + `/parent/list/byCategoryId/${catNameItem.id}`,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      );
      // console.log(response.data);
      setCategoryMM(response.data);
      setCatItem(catNameItem && catNameItem);
    }
  };

  const handleCategorySize = (categorySize) => {
    setCategorySize(categorySize);
    // console.log(categorySize);
    if (categorySize.value) {
      const catSizeItem = categoryMM.find((f) => f.title == categorySize.value);
      setCatMMItem(catSizeItem && catSizeItem);
      axios
        .get(
          localApi +
            `/product/list/byAdminAndCategoryAndParentId/${
              userObj && userObj.id
            }/${catItem.id}/${catSizeItem.id}`,
          {
            headers: {
              Authorization: `Basic ${credentials}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data);

          const pStock = res.data.filter((f) => f.stockSize.length === 1);
          // console.log(pStock);
          setItemProd(pStock);
        });
    }
  };

  // useEffect(() => {
  //   setCategorySize("");
  // }, [categoryName]);

  // useEffect(() => {
  //   setItemProd("");
  // }, [categorySize]);

  const handleDeleteDie = (prod) => {
    setSubmitteds(true);
    setCartId(prod.stockSize[0]);
  };

  const handleConfirm = () => {
    // console.log(cartId);
    fetch(localApi + `/stockSize/delete/byId/${cartId.id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "Deleted Successfully !!!") {
          alertTypeOps("success");
          alertMsgOps("Deleted Succesfully");
          setSubmittedConfirm(true);
        }
      });
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  const hideAlerts = () => {
    setSubmitteds(false);
    setSubmittedConfirm(false);
    window.location.reload(false);
  };

  const select = {
    width: "100%",
    maxWidth: 600,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 10,
    }),
    menu: (state) => ({
      color: "#d81b60",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  return (
    <div>
      <GridContainer
        justify="center"
        alignItems="center"
        style={{ marginBottom: "5%" }}
      >
        {submitted ? (
          <SweetAlert
            type={alertType}
            style={{ display: "block", marginTop: "-100px" }}
            title={alertMsg}
            onConfirm={() => hideAlert()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          />
        ) : null}
        {submitteds ? (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "100px" }}
            title="Are you sure?"
            onConfirm={handleConfirm}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
            cancelBtnCssClass={classSweet.button + " " + classSweet.danger}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
          />
        ) : null}
        {submittedConfirm ? (
          <SweetAlert
            success
            style={{ display: "block", marginTop: "100px" }}
            title="Deleted!"
            onConfirm={() => hideAlerts()}
            onCancel={() => hideAlerts()}
            confirmBtnCssClass={classSweet.button + " " + classSweet.success}
          >
            Your imaginary product has been deleted.
          </SweetAlert>
        ) : null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AiOutlineFolderView />
              </CardIcon>
              <h3 className={classes.cardIconTitle}>
                <small>View Stock Product</small>
              </h3>
            </CardHeader>
            <CardBody>
              <GridContainer style={{ marginBottom: "3%" }}>
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Category Title
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Category Title..."
                    value={categoryName}
                    onChange={handleCategoryName}
                    options={
                      category &&
                      category.map((c) => {
                        return { label: c.title, value: c.title };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <label className={classe.selectLabel}>
                    Select Bangles Size MM
                  </label>
                  <Selects
                    styles={customStyles}
                    style={select}
                    placeholder="Select Bangles Size MM..."
                    value={categorySize}
                    onChange={handleCategorySize}
                    options={
                      categoryMM &&
                      categoryMM.map((c) => {
                        return { label: c.title, value: c.title };
                      })
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        {itemProd &&
          itemProd.map((prod) => (
            <GridItem xs={12} sm={6} md={3} key={prod.id}>
              <Card product className={classeHover.cardHover}>
                <CardHeader image className={classeHover.cardHeaderHover}>
                  <CornerRibbon
                    position={prod.active === true ? "Live" : ""} // position="top-right" // OPTIONAL, default as "top-right"
                    fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                    backgroundColor="black" // OPTIONAL, default as "#2c7"
                    containerStyle={{}} // OPTIONAL, style of the ribbon
                    style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                    className="" // OPTIONAL, css class of ribbon
                  >
                    {" "}
                    {prod.active === true ? "Live" : ""}
                  </CornerRibbon>
                  {/* {prod.productImage.map(i => (
                      <img src={i.cropImageUrl + "?id=" + i.imgId} alt="..." />
                    ))} */}
                  <img
                    src={
                      prod.productimage[0].cpanelPath
                    }
                    alt="..."
                  />
                </CardHeader>
                <CardBody>
                  <div className={classeHover.cardHoverUnder}>
                    <Tooltip
                      id="tooltip-top"
                      title="Edit"
                      placement="bottom"
                      classes={{ tooltip: classeHover.tooltip }}
                    >
                      <Button
                        color="success"
                        simple
                        justIcon
                        onClick={() =>
                          history.push({
                            // pathname: prod.active === false ? `/admin/updateProduct` : `/admin/onlineProductUpdate`,
                            pathname: `/admin/updateStockProduct`,
                            state: prod.stockSize[0],
                          })
                        }
                      >
                        <Edit className={classeHover.underChartIcons} />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="Remove"
                      placement="bottom"
                      classes={{ tooltip: classeHover.tooltip }}
                    >
                      <Button
                        color="danger"
                        simple
                        justIcon
                        onClick={() => {
                          handleDeleteDie(prod);
                        }}
                      >
                        <DeleteForeverIcon
                          className={classeHover.underChartIcons}
                        />
                      </Button>
                    </Tooltip>
                  </div>
                  <h5
                    className={classeHover.cardProductTitle}
                    style={{ color: "#d81b60" }}
                  >
                    {/* {prod.category.title +
                        " " +
                        prod.parent.title}{" "}
                      <br /> {prod.itemcode}- */}
                    Product Id {prod.id}
                  </h5>
                  {/* <h6
                      className={classeHover.cardProductTitle}
                      style={{ fontWeight: "bold" }}
                    >
                      {prod.prodDescription}
                    </h6> */}
                  <p className={classeHover.cardProductDesciprion}>
                    {(prod.readyGram * prod.sets.pcs).toFixed(4)} Avg Gram Of{" "}
                    {prod.sets.pcs} set
                    {/* {prod.box.boxDesc}{" "}
                      {prod.ctndescription} */}
                    <br />
                    <span style={{ color: "green" }}>
                      Making Charge:- {prod.makingCharge.toFixed(2)}
                    </span>
                    <br />
                    <span style={{ color: "black" }}>
                      Cash Price:- {prod.cashPrice.toFixed(2)}
                    </span>
                    <br />
                    <span style={{ color: "#FFA500" }}>
                      Tax All:- {prod.taxAll.toFixed(2)}
                    </span>
                    <br />
                    <span style={{ color: "red" }}>
                      Tax Include:- {prod.taxInclude.toFixed(2)}
                    </span>
                  </p>
                  <GridContainer className={classes.sizePadding}>
                    {prod.stockSize[0].s1x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x8 == null
                          ? ""
                          : prod.stockSize[0].s1x8 == 0
                          ? ""
                          : "1x8"}
                        <br />
                        {prod.stockSize[0].s1x8 == null
                          ? ""
                          : prod.stockSize[0].s1x8 == 0
                          ? ""
                          : prod.stockSize[0].s1x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s1x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x10 == null
                          ? ""
                          : prod.stockSize[0].s1x10 == 0
                          ? ""
                          : "1x10"}
                        <br />
                        {prod.stockSize[0].s1x10 == null
                          ? ""
                          : prod.stockSize[0].s1x10 == 0
                          ? ""
                          : prod.stockSize[0].s1x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s1x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x12 == null
                          ? ""
                          : prod.stockSize[0].s1x12 == 0
                          ? ""
                          : "1x12"}
                        <br />
                        {prod.stockSize[0].s1x12 == null
                          ? ""
                          : prod.stockSize[0].s1x12 == 0
                          ? ""
                          : prod.stockSize[0].s1x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s1x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x14 == null
                          ? ""
                          : prod.stockSize[0].s1x14 == 0
                          ? ""
                          : "1x14"}
                        <br />
                        {prod.stockSize[0].s1x14 == null
                          ? ""
                          : prod.stockSize[0].s1x14 == 0
                          ? ""
                          : prod.stockSize[0].s1x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x0 == null
                          ? ""
                          : prod.stockSize[0].s2x0 == 0
                          ? ""
                          : "2x0"}
                        <br />
                        {prod.stockSize[0].s2x0 == null
                          ? ""
                          : prod.stockSize[0].s2x0 == 0
                          ? ""
                          : prod.stockSize[0].s2x0}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x2 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x2 == null
                          ? ""
                          : prod.stockSize[0].s2x2 == 0
                          ? ""
                          : "2x2"}
                        <br />
                        {prod.stockSize[0].s2x2 == null
                          ? ""
                          : prod.stockSize[0].s2x2 == 0
                          ? ""
                          : prod.stockSize[0].s2x2}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x4 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x4 == null
                          ? ""
                          : prod.stockSize[0].s2x4 == 0
                          ? ""
                          : "2x4"}
                        <br />
                        {prod.stockSize[0].s2x4 == null
                          ? ""
                          : prod.stockSize[0].s2x4 == 0
                          ? ""
                          : prod.stockSize[0].s2x4}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x6 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x6 == null
                          ? ""
                          : prod.stockSize[0].s2x6 == 0
                          ? ""
                          : "2x6"}
                        <br />
                        {prod.stockSize[0].s2x6 == null
                          ? ""
                          : prod.stockSize[0].s2x6 == 0
                          ? ""
                          : prod.stockSize[0].s2x6}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x8 == null
                          ? ""
                          : prod.stockSize[0].s2x8 == 0
                          ? ""
                          : "2x8"}
                        <br />
                        {prod.stockSize[0].s2x8 == null
                          ? ""
                          : prod.stockSize[0].s2x8 == 0
                          ? ""
                          : prod.stockSize[0].s2x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x10 == null
                          ? ""
                          : prod.stockSize[0].s2x10 == 0
                          ? ""
                          : "2x10"}
                        <br />
                        {prod.stockSize[0].s2x10 == null
                          ? ""
                          : prod.stockSize[0].s2x10 == 0
                          ? ""
                          : prod.stockSize[0].s2x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x12 == null
                          ? ""
                          : prod.stockSize[0].s2x12 == 0
                          ? ""
                          : "2x12"}
                        <br />
                        {prod.stockSize[0].s2x12 == null
                          ? ""
                          : prod.stockSize[0].s2x12 == 0
                          ? ""
                          : prod.stockSize[0].s2x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x14 == null
                          ? ""
                          : prod.stockSize[0].s2x14 == 0
                          ? ""
                          : "2x14"}
                        <br />
                        {prod.stockSize[0].s2x14 == null
                          ? ""
                          : prod.stockSize[0].s2x14 == 0
                          ? ""
                          : prod.stockSize[0].s2x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s3x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s3x0 == null
                          ? ""
                          : prod.stockSize[0].s3x0 == 0
                          ? ""
                          : "3x0"}
                        <br />
                        {prod.stockSize[0].s3x0 == null
                          ? ""
                          : prod.stockSize[0].s3x0 == 0
                          ? ""
                          : prod.stockSize[0].s3x0}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  <GridContainer className={classes.sizePadding}>
                    {prod.stockSize[0].s1x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x9 == null
                          ? ""
                          : prod.stockSize[0].s1x9 == 0
                          ? ""
                          : "1x9"}
                        <br />
                        {prod.stockSize[0].s1x9 == null
                          ? ""
                          : prod.stockSize[0].s1x9 == 0
                          ? ""
                          : prod.stockSize[0].s1x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s1x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x11 == null
                          ? ""
                          : prod.stockSize[0].s1x11 == 0
                          ? ""
                          : "1x11"}
                        <br />
                        {prod.stockSize[0].s1x11 == null
                          ? ""
                          : prod.stockSize[0].s1x11 == 0
                          ? ""
                          : prod.stockSize[0].s1x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s1x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x13 == null
                          ? ""
                          : prod.stockSize[0].s1x13 == 0
                          ? ""
                          : "1x13"}
                        <br />
                        {prod.stockSize[0].s1x13 == null
                          ? ""
                          : prod.stockSize[0].s1x13 == 0
                          ? ""
                          : prod.stockSize[0].s1x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s1x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s1x15 == null
                          ? ""
                          : prod.stockSize[0].s1x15 == 0
                          ? ""
                          : "1x15"}
                        <br />
                        {prod.stockSize[0].s1x15 == null
                          ? ""
                          : prod.stockSize[0].s1x15 == 0
                          ? ""
                          : prod.stockSize[0].s1x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x1 == null
                          ? ""
                          : prod.stockSize[0].s2x1 == 0
                          ? ""
                          : "2x1"}
                        <br />
                        {prod.stockSize[0].s2x1 == null
                          ? ""
                          : prod.stockSize[0].s2x1 == 0
                          ? ""
                          : prod.stockSize[0].s2x1}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x3 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x3 == null
                          ? ""
                          : prod.stockSize[0].s2x3 == 0
                          ? ""
                          : "2x3"}
                        <br />
                        {prod.stockSize[0].s2x3 == null
                          ? ""
                          : prod.stockSize[0].s2x3 == 0
                          ? ""
                          : prod.stockSize[0].s2x3}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x5 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x5 == null
                          ? ""
                          : prod.stockSize[0].s2x5 == 0
                          ? ""
                          : "2x5"}
                        <br />
                        {prod.stockSize[0].s2x5 == null
                          ? ""
                          : prod.stockSize[0].s2x5 == 0
                          ? ""
                          : prod.stockSize[0].s2x5}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x7 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x7 == null
                          ? ""
                          : prod.stockSize[0].s2x7 == 0
                          ? ""
                          : "2x7"}
                        <br />
                        {prod.stockSize[0].s2x7 == null
                          ? ""
                          : prod.stockSize[0].s2x7 == 0
                          ? ""
                          : prod.stockSize[0].s2x7}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x9 == null
                          ? ""
                          : prod.stockSize[0].s2x9 == 0
                          ? ""
                          : "2x9"}
                        <br />
                        {prod.stockSize[0].s2x9 == null
                          ? ""
                          : prod.stockSize[0].s2x9 == 0
                          ? ""
                          : prod.stockSize[0].s2x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x11 == null
                          ? ""
                          : prod.stockSize[0].s2x11 == 0
                          ? ""
                          : "2x11"}
                        <br />
                        {prod.stockSize[0].s2x11 == null
                          ? ""
                          : prod.stockSize[0].s2x11 == 0
                          ? ""
                          : prod.stockSize[0].s2x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x13 == null
                          ? ""
                          : prod.stockSize[0].s2x13 == 0
                          ? ""
                          : "2x13"}
                        <br />
                        {prod.stockSize[0].s2x13 == null
                          ? ""
                          : prod.stockSize[0].s2x13 == 0
                          ? ""
                          : prod.stockSize[0].s2x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s2x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s2x15 == null
                          ? ""
                          : prod.stockSize[0].s2x15 == 0
                          ? ""
                          : "2x15"}
                        <br />
                        {prod.stockSize[0].s2x15 == null
                          ? ""
                          : prod.stockSize[0].s2x15 == 0
                          ? ""
                          : prod.stockSize[0].s2x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {prod.stockSize[0].s3x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {prod.stockSize[0].s3x1 == null
                          ? ""
                          : prod.stockSize[0].s3x1 == 0
                          ? ""
                          : "3x1"}
                        <br />
                        {prod.stockSize[0].s3x1 == null
                          ? ""
                          : prod.stockSize[0].s3x1 == 0
                          ? ""
                          : prod.stockSize[0].s3x1}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          ))}
      </GridContainer>
    </div>
  );
}

ViewStockProduct.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
