import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { localApi, credentials } from "utils/imgApi";
import { BiEdit } from "react-icons/bi";
import Compressor from "compressorjs";
import PropTypes from "prop-types";
import { dataURLtoFile } from "utils/dataURLToFile";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import styleDrop from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import SweetAlert from "react-bootstrap-sweetalert";
import styleSweet from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import styleBtn from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);
const useStyle = makeStyles(style);
const useStyleDrop = makeStyles(styleDrop);
const useStyleSweet = makeStyles(styleSweet);
const useStyleBtn = makeStyles(styleBtn);

export default function ImagesAddCrop(props) {
  const [items, setItems] = React.useState([]);
  const [items1, setItems1] = React.useState([]);
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [simpleSelectErr, setSimpleSelectErr] = React.useState("");
  const [simpleSelect1, setSimpleSelect1] = React.useState("");
  const [simpleSelect1Err, setSimpleSelect1Err] = React.useState("");
  const [checkedFullSize, setCheckedFullSize] = React.useState(false);
  const [getId, setGetId] = React.useState("");
  const [getcatId, setGetcatId] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [alertType, alertTypeOps] = React.useState("");
  const [alertMsg, alertMsgOps] = React.useState("");

  const [orgCompFile, setorgCompFile] = React.useState("");
  const [newImage, setNewImage] = React.useState(false);
  const [btn, setBtn] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classDrop = useStyleDrop();
  const history = useHistory();
  const classSweet = useStyleSweet();
  const classBtn = useStyleBtn();

  const { search } = useLocation();
  const a = new URLSearchParams(search).get("a");

  const [file, setFile] = React.useState(null);
  const [fileErr, setFileErr] = React.useState(null);
  const [crop, setCrop] = React.useState({
    aspect: 0.833,
    unit: "px",
    width: 280, //280
    height: 336, //336
  });
  const [image, setImage] = React.useState("");
  const [result, setResult] = React.useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? "" : ""
  );
  let fileInput = React.createRef();

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);

      new Compressor(file, {
        quality: 0.8,
        success: (res) => {
          // console.log("After", res);
          const orgCompress = new File([res], `${file?.name}`, {
            type: res.type,
          });
          setorgCompFile(orgCompress);
        },
      });
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setFileErr("");
  };

  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const base64Image = canvas.toDataURL("image/jpeg");
    setResult(base64Image);
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? "" : "");
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;

  async function getCat() {
    const api = localApi + `/category/list/byAdminId/${a ? a : "1"}`;
    const result = await fetch(api, {
      method: "GET",
      headers: {
        Authorization: `Basic ${credentials}`,
      },
    });
    const getResult = await result.json();
    setItems(getResult);
  }

  useEffect(() => {
    getCat();
    // catApi().then((res) => setItems(res));
  }, []);

  const handleSimple = async (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const item = items.find((item) => item.id === value);
    setSimpleSelect(value);
    setGetId(item.id);
    const response = await axios.get(
      localApi + `/parent/list/byCategoryId/${item.id}`,
      {
        headers: {
          Authorization: `Basic ${credentials}`,
        },
      }
    );
    setItems1(response.data);
    setSimpleSelectErr("");
  };

  const handleSimple1 = (event) => {
    // setSimpleSelect(event.target.value);
    const { value } = event.target;
    const itemm = items1.find((itemm) => itemm.id === value);
    setSimpleSelect1(value);
    setGetcatId(itemm.id);
    // console.log(imgApi + "" + itemm.image);
    setSimpleSelect1Err("");
  };

  const SubmitAddImage = (e) => {
    setNewImage(true);
    let errorCount = 0;
    if (simpleSelect === "" || !simpleSelect) {
      errorCount++;
      setSimpleSelectErr("Select Category");
    }
    if (simpleSelect1 === "" || !simpleSelect1) {
      errorCount++;
      setSimpleSelect1Err("Select Product Size");
    }
    if (file === "" || !file) {
      errorCount++;
      setFileErr("of fix size (atleast width-280, height-336 pixel)");
    }
    if (errorCount === 0) {
        setBtn(true);
      const convertedUrlToFile = dataURLtoFile(
        file ? result : null,
        `${file?.name}`
      );

      new Compressor(convertedUrlToFile, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          const compressFile = new File([compressedResult], `${file?.name}`, {
            type: compressedResult.type,
          });
          // console.log(compressFile);
          

          let fData = new FormData();
          fData.append("originalImage", orgCompFile || new File([""], ""));
          fData.append("cropImage", compressFile || file);
          fData.append(
            "data",
            new Blob(
              [
                JSON.stringify({
                  adminId: a ? a : "1",
                  categoryId: getId,
                  parentId: getcatId,
                }),
              ],
              {
                type: "application/json",
              }
            )
          );

          axios
            .post(localApi + "/image/uploadImage", fData, {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            })
            .then((response) => {
              // console.log(response.data);
              if (response.data.message === "Image Inserted Successfully") {
                alertTypeOps("success");
                alertMsgOps("Succesfully !!!");
                setSubmitted(true);
              }
            });
        },
      });
    }
  };

  const hideAlert = () => {
    setSubmitted(false);
    window.location.reload(false);
  };

  useEffect(() => {
    // console.log("Value Changed")
    setSimpleSelect1("");
    setGetcatId("");
  }, [simpleSelect]);

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <form>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <BiEdit />
                </CardIcon>
                <h3 className={classes.cardIconTitle}>
                  <small>Add Image</small>
                  {newImage === true ? (
                    <small
                      style={{
                        fontSize: "12px",
                        color: "red",
                        marginLeft: "10px",
                      }}
                    >
                      {simpleSelectErr || simpleSelect1Err || fileErr}
                    </small>
                  ) : (
                    <small style={{ fontSize: "12px", marginLeft: "10px" }}>
                      Upload Normal Image and then Crop it
                    </small>
                  )}
                </h3>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  {submitted ? (
                    <SweetAlert
                      type={alertType}
                      style={{ display: "block", marginTop: "-100px" }}
                      title={alertMsg}
                      onConfirm={() => hideAlert()}
                      confirmBtnCssClass={
                        classSweet.button + " " + classSweet.success
                      }
                    >
                      Added Image
                    </SweetAlert>
                  ) : null}{" "}
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Category Title...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelect}
                        onChange={handleSimple}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Category title...
                        </MenuItem>
                        {items &&
                          items.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={6} md={6}>
                    <FormControl
                      fullWidth
                      className={classDrop.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classDrop.selectLabel}
                      >
                        Select Bangles Size-MM...
                      </InputLabel>

                      <Select
                        MenuProps={{
                          className: classDrop.selectMenu,
                        }}
                        classes={{
                          select: classDrop.select,
                        }}
                        value={simpleSelect1}
                        onChange={handleSimple1}
                        inputProps={{
                          name: "simpleSelect",
                          id: "simple-select",
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classDrop.selectMenuItem,
                          }}
                        >
                          Select Bangles Size-MM...
                        </MenuItem>
                        {items1 &&
                          items1.map((item1) => (
                            <MenuItem
                              key={item1.id}
                              value={item1.id}
                              classes={{
                                root: classDrop.selectMenuItem,
                                selected: classDrop.selectMenuItemSelected,
                              }}
                            >
                              {item1.title}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>{" "}
                  <GridItem xs={12} sm={12} md={12}>
                  <div className="fileinput text-center">
                    <input
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInput}
                    />

                    {file && (
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={10}
                          className={classes.imgResponvise}
                        >
                          <ReactCrop
                            src={imagePreviewUrl}
                            onImageLoaded={setImage}
                            crop={crop}
                            onChange={setCrop}
                            minWidth={280 < 280 ? 280 : 280}
                            minHeight={336 < 336 ? 336 : 336}
                            // style={{ width: "500px" }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button color="rose" round onClick={getCroppedImg}>
                            {" "}
                            Crop Image
                          </Button>
                        </GridItem>
                      </GridContainer>
                    )}

                    <GridContainer
                      className={"thumbnail" + (avatar ? " img-circle" : "")}
                    >
                      {result && <img src={result} alt="..." />}
                    </GridContainer>

                    <GridContainer>
                      {file === null ? (
                        <Button
                          color="rose"
                          round
                          {...addButtonProps}
                          onClick={() => handleClick()}
                        >
                          {avatar ? "Add Photo" : "Add Image"}
                        </Button>
                      ) : (
                        <span>
                          <Button
                            color="rose"
                            round
                            {...changeButtonProps}
                            onClick={() => handleClick()}
                          >
                            Change
                          </Button>
                          {avatar ? <br /> : null}
                          <Button
                            color="rose"
                            round
                            {...removeButtonProps}
                            onClick={() => handleRemove()}
                          >
                            <i className="fas fa-times" /> Remove
                          </Button>
                        </span>
                      )}
                    </GridContainer>
                  </div>
                  </GridItem>{" "}
                </GridContainer>
                {btn === true ? (
                  <Button className={classBtn.updateProfileButton}>Add</Button>
                ) : (
                  <Button
                    color="rose"
                    onClick={SubmitAddImage}
                    className={classBtn.updateProfileButton}
                  >
                    Add
                  </Button>
                )}
              </CardBody>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

ImagesAddCrop.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
